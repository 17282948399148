const config = {
  domain: '',
  serverUrl: '',
  imageServerUrl: '',
}

export const initalize = newConfig => {
  Object.assign(config, newConfig)
}

export default config

import axios from 'axios'
import Detector from 'detector-js'
import { getWebGlReport } from '@/utils/diagnostic/webGL'
import {
  isEdgeChromium,
  getBrowserNiceName,
  isBrowserSupported,
  isBrowserUpToDate,
} from '@/utils/diagnostic/browsers'
import speedTest from '@/utils/diagnostic/speedTest'
import {
  getGpuFullName,
  getGpuLongName,
  getGpuShortName,
  getGpuModel,
  getGpuIsNvidiaOrAmd,
  getGpuIsIntel,
  getGpuBenchmark,
} from '@/utils/diagnostic/gpu'

// https://gitlab.geolithe.com/loic.roybon/rim-nat/-/blob/master/02_Deployment/www/app.rimnat.com/files/src/views/Diagnostic.vue

export const getBrowserCache = () => {
  const result = performance
    .getEntriesByType('resource')
    .filter(resource => resource.initiatorType == 'script')
    .filter(resource => resource.name.includes('rimnat'))[0]

  const { transferSize } = result || {}

  return {
    enabled: Boolean(transferSize && transferSize <= 250),
    transferSize,
    testedEntity: result,
  }
}

const IPDATA_URL = 'https://api.ipdata.co/'
const IPDATA_KEY = '140859599e0c9f997c95a47892839a818276fcb277a28771809de3bb'

export const getIpData = async () => {
  try {
    const params = { 'api-key': IPDATA_KEY }

    const { data } = await axios.get(IPDATA_URL, { params })

    return data
  } catch (error) {
    return null
  }
}

export const getDevice = () => {
  const device = new Detector()
  if (isEdgeChromium() && device?.browser) {
    device.browser.name = 'edge_chromium'
  }

  // detector-js is adding classNames in <html> tag which were messing with vue.js
  document.documentElement.className = document.documentElement.className
    .replace(/djs[a-z-0-9]*/g, '')
    .trim()
  return device
}

export const getWebGlIsSupported = webGLReport =>
  webGLReport.glVersion.toLowerCase().includes('webgl') &&
  webGLReport.shadingLanguageVersion.toLowerCase().includes('gl') &&
  parseInt(webGLReport.maxAnisotropy) >= 16 &&
  webGLReport.antialias.toLowerCase() === 'available' &&
  webGLReport.webgl2Status !== '88 of 88 new functions implemented.'

export const getOSNiceName = os => `${os.name.toLowerCase()} ${os.versionName}`

export const doNotTrackEnabled = () =>
  navigator.doNotTrack !== undefined ? navigator.doNotTrack : window.doNotTrack

// https://threejs.org/docs/#api/en/renderers/WebGLRenderer.getContext
export const getFullReport = async (context /* : WebGLRenderingContext */) => {
  const device = getDevice(navigator.userAgent)

  const gpuFullName = getGpuFullName(device)
  const gpuLongName = getGpuLongName(gpuFullName)
  const gpuShortName = getGpuShortName(gpuLongName)
  const gpuModel = getGpuModel(gpuShortName)

  const webGLReport = getWebGlReport(context)

  const webGlIsSupported = getWebGlIsSupported(webGLReport)
  const gpuBenchmark = await getGpuBenchmark(gpuModel)

  return {
    computedValues: {
      gpuFullName,
      gpuLongName,
      gpuShortName,
      gpuModel,
      isEdgeChromium: isEdgeChromium(),
      gpuIsNvidiaOrAmd: getGpuIsNvidiaOrAmd(gpuFullName),
      gpuIsIntel: getGpuIsIntel(gpuFullName),
      webGlIsSupported,
      speedTest: speedTest(device),
    },
    summaryReport: {
      os: getOSNiceName(device.os),
      browser: getBrowserNiceName(device.browser),
      isBrowserSupported: isBrowserSupported(device.browser),
      isBrowserUpToDate: isBrowserUpToDate(device.browser),
      gpuFullName,
      gpuLongName,
      gpuShortName,
      gpuModel,
      gpuScore: gpuBenchmark.score,
      webGL: webGlIsSupported,
      cookies: navigator.cookieEnabled,
      browserCache: getBrowserCache().enabled,
      doNotTrack: navigator.doNotTrack,
    },
    ipData: await getIpData(),
    gpuBenchmark,
    device,
    gl: webGLReport,
  }
}

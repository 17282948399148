const webGL2FunctionNames = [
  'copyBufferSubData',
  'getBufferSubData',
  'blitFramebuffer',
  'framebufferTextureLayer',
  'getInternalformatParameter',
  'invalidateFramebuffer',
  'invalidateSubFramebuffer',
  'readBuffer',
  'renderbufferStorageMultisample',
  'texStorage2D',
  'texStorage3D',
  'texImage3D',
  'texSubImage3D',
  'copyTexSubImage3D',
  'compressedTexImage3D',
  'compressedTexSubImage3D',
  'getFragDataLocation',
  'uniform1ui',
  'uniform2ui',
  'uniform3ui',
  'uniform4ui',
  'uniform1uiv',
  'uniform2uiv',
  'uniform3uiv',
  'uniform4uiv',
  'uniformMatrix2x3fv',
  'uniformMatrix3x2fv',
  'uniformMatrix2x4fv',
  'uniformMatrix4x2fv',
  'uniformMatrix3x4fv',
  'uniformMatrix4x3fv',
  'vertexAttribI4i',
  'vertexAttribI4iv',
  'vertexAttribI4ui',
  'vertexAttribI4uiv',
  'vertexAttribIPointer',
  'vertexAttribDivisor',
  'drawArraysInstanced',
  'drawElementsInstanced',
  'drawRangeElements',
  'drawBuffers',
  'clearBufferiv',
  'clearBufferuiv',
  'clearBufferfv',
  'clearBufferfi',
  'createQuery',
  'deleteQuery',
  'isQuery',
  'beginQuery',
  'endQuery',
  'getQuery',
  'getQueryParameter',
  'createSampler',
  'deleteSampler',
  'isSampler',
  'bindSampler',
  'samplerParameteri',
  'samplerParameterf',
  'getSamplerParameter',
  'fenceSync',
  'isSync',
  'deleteSync',
  'clientWaitSync',
  'waitSync',
  'getSyncParameter',
  'createTransformFeedback',
  'deleteTransformFeedback',
  'isTransformFeedback',
  'bindTransformFeedback',
  'beginTransformFeedback',
  'endTransformFeedback',
  'transformFeedbackVaryings',
  'getTransformFeedbackVarying',
  'pauseTransformFeedback',
  'resumeTransformFeedback',
  'bindBufferBase',
  'bindBufferRange',
  'getIndexedParameter',
  'getUniformIndices',
  'getActiveUniforms',
  'getUniformBlockIndex',
  'getActiveUniformBlockParameter',
  'getActiveUniformBlockName',
  'uniformBlockBinding',
  'createVertexArray',
  'deleteVertexArray',
  'isVertexArray',
  'bindVertexArray',
]

export default webGL2FunctionNames

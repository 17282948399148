import { v4 as uuidv4 } from 'uuid'
import { has } from 'lodash'
import {
  convertToHttpToCreate,
  convertToJs,
  convertToHttp,
} from '@/utils/modelConvertors/annotation.converter'
import { safeArray } from '@/utils/array'
import { removeEmptyProperties } from '@/utils/object'
import { formDataHeaders } from '@/utils/form-data'

async function postAnnotation(api, userId, annotation) {
  const httpAnnotationWithoutId = removeEmptyProperties(
    convertToHttpToCreate(annotation)
  )
  const form = new FormData()
  form.append('user_id', parseInt(userId, 10))
  form.append('project_id', parseInt(annotation.projectId, 10))
  form.append('data', JSON.stringify(httpAnnotationWithoutId))
  try {
    const { data } = await api.post(
      '/project/createProjectTag',
      form,
      formDataHeaders
    )
    if (!data) return { error: 'no result' }
    else if (data.status === 'error') return { error: data }
    else return { success: { ...annotation, id: data.result } }
  } catch {
    // if we did not get a response from the server, assume it is a connectivity issue
    // store annotation locally for now
    const id = 'local_' + uuidv4()
    return { pending: { ...annotation, id } }
  }
}

export const createAnnotation = async function (
  { rootGetters, commit, dispatch },
  { annotation }
) {
  const creation = await postAnnotation(
    this.$api,
    rootGetters.currentUser?.id,
    annotation
  )
  if (has(creation, 'error')) {
    // the API returned an error, abort annotation creation
    throw creation.error
  }
  const created = creation.success || creation.pending
  if (!created) return
  created.raw = convertToHttp(created)
  await commit('addAnnotation', {
    annotation: created,
    pending: creation.pending != null,
  })
  dispatch('openAnnotation', created)
}

export async function savePendingAnnotation(
  { getters, rootGetters, commit },
  { annotationId }
) {
  const annotation = getters.getAnnotationById(annotationId)
  if (annotation == null) return
  const mediaId = annotation.mediaId
  if (rootGetters.isPendingMediaId(mediaId)) {
    console.log(
      `cannot save pending annotation ${annotationId} because it requires pending media ${mediaId}`
    )
    return
  }
  if (mediaId) {
    const actualMediaId = rootGetters.pendingMediaIdToFinalId(mediaId)
    if (actualMediaId) annotation.mediaId = actualMediaId
  }
  const creation = await postAnnotation(
    this.$api,
    rootGetters.currentUser?.id,
    annotation
  )
  if (has(creation, 'error')) {
    console.error(creation.error)
  } else if (creation.success) {
    commit('setAnnotationServerId', {
      localId: annotationId,
      serverId: creation.success.id,
    })
  }
}

export async function fetchAnnotationsForProject(
  { state, commit, dispatch },
  { projectId }
) {
  const options = {
    params: {
      project_id: projectId,
    },
  }

  const url = '/project/getProjectTags'

  const { data } = await this.$api.get(url, options)

  if (!data) {
    return
  }

  // make sure we don't lose pending annotations when replacing with server data
  const pending = safeArray(state.pendingAnnotationIds)
    .map(id => state.byId[id])
    .filter(a => a.projectId === projectId)
  const annotations = [...data.map(convertToJs), ...pending]

  commit('setAnnotations', { annotations, projectId })

  const promises = annotations
    .filter(a => a.mediaId)
    .map(annotation => dispatch('fetchMedia', annotation.mediaId))

  await Promise.all(promises)

  return annotations
}

export const openAnnotation = ({ commit }, annotation) => {
  commit('setDisplayedAnnotation', {
    projectId: annotation?.projectId,
    annotationId: annotation?.id,
  })
}

export const closeAnnotation = ({ commit }, { projectId }) => {
  commit('setDisplayedAnnotation', {
    projectId,
    annotationId: null,
  })
}

const updateAnnotationParameter = async (
  api,
  { projectId, annotationId, parameter, value }
) => {
  const form = new FormData()
  form.append('tag_id', parseInt(annotationId, 10))
  form.append('project_id', parseInt(projectId, 10))
  form.append('parameter', parameter)
  form.append(' value', value)

  const url = '/project/updateProjectTag'

  const { data } = await api.post(url, form, formDataHeaders)

  if (!data) {
    throw new Error('no result')
  }

  if (data.status === 'error') {
    throw data
  }
}

const parameterChanged = (annotation, oldAnnotation) => p =>
  annotation[p] !== oldAnnotation[p]

export async function updateAnnotation(
  { commit, getters, dispatch },
  { annotation }
) {
  const oldHttpAnnotation = convertToHttp(
    getters.getAnnotationById(annotation.id)
  )
  const httpAnnotation = convertToHttp(annotation)

  try {
    const promises = Object.keys(httpAnnotation)
      .filter(parameterChanged(httpAnnotation, oldHttpAnnotation))
      .map(parameter =>
        updateAnnotationParameter(this.$api, {
          projectId: annotation.projectId,
          annotationId: annotation.id,
          parameter,
          value: httpAnnotation[parameter],
        })
      )

    await Promise.all(promises)

    commit('changeAnnotation', { annotation })

    if (annotation.mediaId) {
      await dispatch('fetchMedia', annotation.mediaId)
    }
  } catch (error) {
    console.error(error)
  }
}

export async function removeAnnotation({ commit }, { annotation }) {
  if (annotation?.id == null) return
  const form = new FormData()
  form.append('tag_id', parseInt(annotation.id, 10))
  form.append('project_id', parseInt(annotation.projectId, 10))

  const url = '/project/deleteProjectTag'

  const { data } = await this.$api.post(url, form, formDataHeaders)

  if (!data) {
    throw new Error('no result')
  }

  if (data === 'ok') {
    commit('deleteAnnotation', {
      projectId: annotation.projectId,
      annotationId: annotation.id,
    })
  }
}

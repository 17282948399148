function removeObjectURL(state, id) {
  if (!id) return
  const objectUrl = state.idToObjectURLs[id]
  if (objectUrl) {
    URL.revokeObjectURL(objectUrl)
    delete state.idToObjectURLs[id]
  }
}

export const setMedia = (state, { media }) => {
  const mediaId = media?.id
  if (!mediaId) return
  // remove previous object URL (if any) to avoid memory leak
  removeObjectURL(state, mediaId)
  // create new object URL if needed
  if (!media.url && media.image != null) {
    media.url = URL.createObjectURL(media.image)
    state.pendingMediaIds.push(mediaId)
    state.idToObjectURLs[mediaId] = media.url
  }
  state.byId[mediaId] = media
}

export const setMediaServerId = (state, { localId, serverId, url }) => {
  removeObjectURL(state, localId)
  const media = state.byId[localId]
  if (!media) return
  const idx = state.pendingMediaIds.indexOf(localId)
  if (idx >= 0) state.pendingMediaIds.splice(idx, 1)
  delete state.byId[localId]
  delete media.image
  media.id = serverId
  media.url = url
  state.byId[serverId] = media
  state.pendingIdToFinalId[localId] = serverId
}

import axios from 'axios'
/**
 * How to make make API requests following this [tutorial](https://auth0.com/blog/complete-guide-to-vue-user-authentication/#Calling-an-API)
 */

let instance = null

export const api = () => instance

const useApi = options => {
  instance = axios.create({
    baseURL: `${options.serverUrl}/api`,
    timeout: 3000,
    headers: {
      'Referrer-Policy': 'strict-origin-when-cross-origin',
      'X-Rimnat-AppId': options.appId,
    },
  })

  instance.interceptors.request.use(
    request => {
      request.headers.common['X-Rimnat-Timestamp'] = new Date().getTime()
      return request
    },
    error => {
      // Do something with request error
      console.error(error)
      return Promise.reject(error)
    }
  )

  return instance
}

const utf8_to_b64 = str =>
  window.btoa(unescape(encodeURIComponent(str))).replace(/=/g, '')

export const updateAuthorization = token => {
  const encodedToken = utf8_to_b64(token)

  instance.defaults.headers.common['X-Rimnat-Tk'] = encodedToken
}

export const ApiPlugin = {
  async install(app, options) {
    useApi(options)
    app.config.globalProperties.$api = instance
    app.config.globalProperties.$store.$api = instance

    // const refresh = async () => {
    //   try {
    //     const idToken = app.config.globalProperties.$store.getters.idToken
    //     console.log('refresh', idToken)

    //     updateAuthorization(idToken)
    //   } catch (error) {
    //     console.log('impossible to refresh authentification', error)
    //   }
    // }

    // await refresh()

    app.config.globalProperties.$store.$api.updateAuthorization =
      updateAuthorization

    // app.config.globalProperties.$store.dispatch('refreshApi')

    // app.config.globalProperties.$apiRefresh = refresh
    // app.config.globalProperties.$auth.updateApi = refresh
  },
}

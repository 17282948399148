import { uniq } from 'lodash'
import { safeArray } from '@/utils/array.js'

export const annotationColors = state => state.colors

export const getAnnotations = state => projectId =>
  safeArray(state.listOfIdsByProjectId[projectId])
    .filter(id => !state.areDeleting.includes(id))
    .map(id => state.byId[id])

export const getAnnotationById = state => id =>
  state.areDeleting.includes(id) ? null : state.byId[id]

export const getGroups = state => projectId =>
  uniq(getAnnotations(state)(projectId).map(a => a.group))

export const getDisplayedAnnotation =
  (state, getters, rootState, rootGetters) => projectId =>
    rootGetters.getAnnotationById(
      state.displayedAnnotationByProjectId[projectId]?.displayedAnnotationId
    )

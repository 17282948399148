export const setDiagnosticRunOnThisComputer = state => {
  state.persistent.runOnThisComputer = true
}

export const startDiagnostic = (state, { silent }) => {
  state.shouldRunSilently = silent
  state.isRunning = true
}

export const stopDiagnostic = state => {
  state.isRunning = false
}

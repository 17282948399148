import { ViewerStatus } from '@/utils/viewer'

import { addReset } from '../utils'
import * as actions from './viewer.actions'
import * as mutations from './viewer.mutations'
import * as getters from './viewer.getters'

const state = {
  status: ViewerStatus.DEFAULT,
  byProjectId: {},
}

export default addReset({
  state,
  mutations,
  actions,
  getters,
})

import { addReset } from '../utils'

const state = {
  byProjectId: {},
}

export default addReset({
  state,
  mutations: {},
  actions: {},
  getters: {
    getActivities: () => () =>
      [
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
        {
          id: '1',
          label: 'Accès au portal',
          date: '09/11/2021 12:32',
        },
      ],
  },
})

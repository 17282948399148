export function fromBackgroundFetchRegistration(reg, { name, projectId } = {}) {
  if (!reg) return {}
  const result = {
    id: reg.id,
    downloaded: reg.downloaded,
    inProgress: reg.result === '',
  }
  if (reg.result === 'failure') result.error = reg.failureReason
  if (name) result.name = name
  if (projectId) result.projectId = projectId
  return result
}

export const addBackgroundFetch = (
  state,
  { registration, name, projectId }
) => {
  if (!registration) return
  const obj = fromBackgroundFetchRegistration(registration, { name, projectId })
  state.byId[registration.id] = obj
}

export const updateBackgroundFetch = (state, { registration }) => {
  if (!registration) return
  const current = state.byId[registration.id]
  if (current) {
    const updated = fromBackgroundFetchRegistration(registration)
    Object.assign(current, updated)
  }
}

import { cloneDeep } from 'lodash'

export const convertBackgroundFromViewer = bg => {
  switch (bg) {
    case 'null':
      return 'globe3d'
    case 'gradient':
      return 'black'
    default:
      return 'white'
  }
}

export const convertBackgroundToViewer = background => {
  if (background === 'white') {
    return 'white'
  } else if (background === 'black') {
    return 'gradient'
  }

  return 'null'
}

export const convertUserToViewer = user =>
  user?.raw
    ? {
        Info: user.raw,
        ProjectCaps: {
          can_project_tools_use: '1',
          can_project_tags_add: '1',
          can_project_tags_see: '1',
        },
      }
    : null

export const convertProjectToViewer = project => ({
  id: parseInt(project.id),
  portal_id: project.portalId ? parseInt(project.portalId) : null,
  legend_media_id: project.legendMediaId
    ? parseInt(project.legendMediaId)
    : null,
  name: project.name,
  slug: project.slug,
  coordinate_system: project.coordinateSystem?.proj4 || '',
})

export const convertLayerToViewer = layer => {
  const httpLayer = layer?.raw

  if (typeof httpLayer !== 'object') return httpLayer
  const viewerLayer = {}
  // TODO use reduce to make block pure
  Object.getOwnPropertyNames(httpLayer).forEach(name => {
    const value = httpLayer[name]
    viewerLayer[name] =
      typeof value === 'string' && value.length === 0 ? null : value
  })
  return viewerLayer
}

export const convertProjectAndLayersToViewer = (project, layers, annotations) =>
  project && layers.length > 0
    ? {
        layers: layers.map(convertLayerToViewer),
        tags: annotations.map(a => cloneDeep(a.raw)),
        ...convertProjectToViewer(project),
      }
    : null

export const convertAnnotationToViewer = annotation => ({
  type: annotation.type,
  title: annotation.title,
  description: annotation.description,
  group: annotation.group,
  url: annotation.url,
  media_id: parseInt(annotation.mediaId) || 0,
  color: annotation.color,
})

// we convert only target, camera and placeholder because it is only
// the properties we need
export const convertAnnotationFromViewer = viewerAnnotation => ({
  placeholder: {
    x: viewerAnnotation.placeholder_x,
    y: viewerAnnotation.placeholder_y,
    z: viewerAnnotation.placeholder_z,
  },
  camera: {
    x: viewerAnnotation.camera_x,
    y: viewerAnnotation.camera_y,
    z: viewerAnnotation.camera_z,
  },
  target: {
    x: viewerAnnotation.target_x,
    y: viewerAnnotation.target_y,
    z: viewerAnnotation.target_z,
  },
})

export const convertToWorkStateObject = input => {
  if (input == null) return null
  if (typeof input === 'object') return input
  if (typeof input === 'string') return JSON.parse(input)
  return undefined
}

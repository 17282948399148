import {
  ConnectionKind,
  ConnectionSpeed,
  defaultInfoForLayer,
  defaultInfoForProject,
  isInProgressStatus,
  offlineInfo,
  OfflineItemStatus,
} from './offline.utils'
import { values } from 'lodash'
import { safeArray } from '@/utils/array.js'

function getFetchStatus(fetch) {
  const result = typeof fetch.result === 'string' ? fetch.result : ''
  if (result.length === 0) {
    return fetch.total === 0
      ? OfflineItemStatus.pending
      : OfflineItemStatus.downloading
  } else if (fetch.downloaded === 0) {
    return OfflineItemStatus.failed
  } else {
    return fetch.downloaded === fetch.total
      ? OfflineItemStatus.yes
      : OfflineItemStatus.partial
  }
}

function updateInfoWithFetch(info, fetch) {
  info.total = fetch.total
  info.current = fetch.downloaded
  info.fetch = fetch
  info.status = getFetchStatus(fetch)
  return info
}

function getProjectStatusFromComponentStatus(setOfStatus) {
  if (setOfStatus.size === 0) return OfflineItemStatus.no
  else if (setOfStatus.size === 1) return Array.from(setOfStatus)[0]
  else if (setOfStatus.has(OfflineItemStatus.pending))
    return OfflineItemStatus.pending
  else if (setOfStatus.has(OfflineItemStatus.downloading))
    return OfflineItemStatus.downloading
  else if (setOfStatus.has(OfflineItemStatus.partial))
    return OfflineItemStatus.partial
  else if (setOfStatus.has(OfflineItemStatus.failed))
    return OfflineItemStatus.failed
  else return OfflineItemStatus.yes
}

function updateProjectInfo(projectInfo) {
  let total = 0
  let current = 0
  let status = new Set()
  function accumulate(info) {
    total += info.total || 0
    current += info.current || 0
    status.add(info.status)
  }
  values(projectInfo?.layers).forEach(l => accumulate(l))
  accumulate(projectInfo?.annotations)
  projectInfo.total = total
  projectInfo.current = current
  projectInfo.status = getProjectStatusFromComponentStatus(status)
  return projectInfo
}

function addLayerInfoToState(state, layerId, doInit) {
  let info = state.byLayerId[layerId]
  if (isInProgressStatus(info?.status)) {
    info = defaultInfoForLayer(layerId)
    info.status = OfflineItemStatus.pending
    if (typeof doInit === 'function') doInit(info)
    state.byLayerId[layerId] = info
  }
  return info
}

function addProjectInfoToState(state, projectId, doInit) {
  let info = state.byProjectId[projectId]
  if (isInProgressStatus(info?.status)) {
    info = defaultInfoForProject(projectId)
    info.status = OfflineItemStatus.pending
    if (typeof doInit === 'function') doInit(info)
    state.byProjectId[projectId] = info
  }
  return info
}

export const setNetworkInfo = (
  state,
  { kind, speed, downlink, roundTripTime }
) => {
  if (kind !== undefined) state.networkInfo.kind = kind
  if (downlink !== undefined) state.networkInfo.downlink = downlink
  if (roundTripTime !== undefined)
    state.networkInfo.roundTripTime = roundTripTime
  if (speed !== undefined) state.networkInfo.theoreticalSpeed = speed
  if (state.networkInfo.kind === ConnectionKind.none) {
    state.networkInfo.actualSpeed = ConnectionSpeed.none
  } else if (state.networkInfo.theoreticalSpeed == null) {
    state.networkInfo.actualSpeed = ConnectionSpeed.unknown
  } else {
    state.networkInfo.actualSpeed = state.networkInfo.theoreticalSpeed
  }
}

export const initLayerOffline = (state, { layerId }) => {
  return addLayerInfoToState(state, layerId)
}

export const initProjectOffline = (state, { projectId, layers }) => {
  return addProjectInfoToState(state, projectId, projectInfo => {
    safeArray(layers)
      .map(layer => addLayerInfoToState(state, layer.id))
      .filter(Boolean)
      .forEach(layerInfo => {
        projectInfo.layers[layerInfo.layerId] = layerInfo
        layerInfo.usedByProjectIds.push(projectId)
      })
    projectInfo.annotations = offlineInfo()
  })
}

// Layers can be shared between several projects,
// so we track fetching of layers independently of referencing projects.
// When a layer fetch progresses, we need to update the status of that layer,
// and the status of all projects referencing the layer.
// The 'referencing projects' relationship is modeled by the layer fetch info
// property `usedByProjectIds`.
export const updateProjectsAfterLayerFetchProgress = (
  state,
  { layerId, fetch }
) => {
  const layerInfo = updateInfoWithFetch(
    state.byLayerId[layerId] || offlineInfo(),
    fetch
  )
  safeArray(layerInfo?.usedByProjectIds)
    .map(projectId => state.byProjectId[projectId])
    .filter(Boolean)
    .forEach(projectInfo => updateProjectInfo(projectInfo))
}

// Annotations belong to a single project (they are not shared),
// so annotations are not downloaded without their owning project.
// There is no need to track an annotation to project relationship
// because we update annotation status and the owning project
// in this mutation.
export const updateProjectsAfterAnnotationsFetchProgress = (
  state,
  { projectId, fetch }
) => {
  const projectInfo = state.byProjectId[projectId]
  if (!projectInfo) return
  projectInfo.annotations = updateInfoWithFetch(
    projectInfo.annotations || offlineInfo(),
    fetch
  )
  updateProjectInfo(projectInfo)
}

export const deleteAllOffline = state => {
  const projects = values(state.byProjectId)
  const layers = values(state.byLayerId)
  state.byProjectId = {}
  state.byLayerId = {}
  projects.forEach(projectInfo => (projectInfo.layers = {}))
  layers.forEach(layerInfo => (layerInfo.usedByProjectIds = []))
}

export const deleteProjectOffline = (state, { projectId }) => {
  const projectInfo = state.byProjectId[projectId]
  if (!projectInfo) return
  projectInfo.layers = {}
  projectInfo.annotations = offlineInfo()
  const layerIdsToRemove = []
  values(state.byLayerId).forEach(layerInfo => {
    layerInfo.usedByProjectIds = layerInfo.usedByProjectIds.filter(
      id => id !== projectId
    )
    if (layerInfo.usedByProjectIds.length === 0)
      layerIdsToRemove.push(layerInfo.layerId)
  })
  if (layerIdsToRemove)
    deleteLayersOffline(state, { layerIds: layerIdsToRemove })
  delete state.byProjectId[projectId]
}

export const deleteLayersOffline = (state, { layerIds = [] } = {}) => {
  safeArray(layerIds).forEach(layerId => {
    const layerInfo = state.byLayerId[layerId]
    if (!layerInfo) return
    layerInfo.usedByProjectIds
      .map(id => state.byProjectId[id])
      .filter(Boolean)
      .forEach(projectInfo => delete projectInfo.layers[layerId])
    delete state.byLayerId[layerId]
  })
}

export const clearAllProjectsOfflineStatus = state => {
  state.offlineStatusByProjectId = {}
}

export const clearProjectOfflineStatus = (state, { projectId }) => {
  delete state.offlineStatusByProjectId[projectId]
}

export const setProjectOfflineStatus = (state, { projectId, status }) => {
  state.offlineStatusByProjectId[projectId] =
    typeof status === 'string' ? status : null
}

export const setStorageInfo = (state, info) => {
  state.storage = info && typeof info === 'object' ? info : {}
}

import { Network } from '@capacitor/network'
import { ConnectionKind, ConnectionSpeed } from '@/store/offline/offline.utils'

const networkToConnectionSpeed = type => {
  if (typeof type !== 'string') return ConnectionSpeed.unknown
  switch (type.toLowerCase()) {
    case 'slow-2g':
    case '2g':
      return ConnectionSpeed.slow
    case '3g':
      return ConnectionSpeed.medium
    default:
      if (type.match(/\dg/)) return ConnectionSpeed.fast
      return ConnectionSpeed.unknown
  }
}

function subscribeToNetworkInformation(store) {
  const network = navigator.connection
  if (typeof network?.addEventListener !== 'function') return null
  const changeHandler = () => {
    // { downlink: double [mb/s], effectiveType: string, rtt: number [ms] }
    const {
      effectiveType,
      downlink,
      rtt: roundTripTime,
    } = navigator.connection || {}
    const speed = networkToConnectionSpeed(effectiveType)
    store.commit('setNetworkInfo', { speed, downlink, roundTripTime })
  }
  network.addEventListener('change', changeHandler)
  changeHandler()
  return () => network.removeEventListener('change', changeHandler)
}

const ionicTypeToConnectionKind = type => type || ConnectionKind.none

function subscribeToIonic(store) {
  if (typeof Network.addListener !== 'function') return null
  // status: { connected: boolean, connectionType: string }
  const statusChanged = status => {
    const kind =
      status.connected === false
        ? ConnectionKind.none
        : ionicTypeToConnectionKind(status.connectionType)
    store.commit('setNetworkInfo', { kind })
  }
  const handler = Network.addListener('networkStatusChange', statusChanged)
  Network.getStatus().then(statusChanged)
  return () => handler.remove()
}

function subscribeToOnOffline(store) {
  const offline = () =>
    store.commit('setNetworkInfo', { kind: ConnectionKind.none, speed: 0 })
  const online = () =>
    store.commit('setNetworkInfo', {
      type: ConnectionKind.unknown,
      speed: null,
    })
  if (window.navigator.onLine) online()
  else offline()
  window.addEventListener('offline', offline)
  window.addEventListener('online', online)
  return () => {
    window.removeEventListener('offline', offline)
    window.removeEventListener('online', online)
  }
}

export const networkPlugin = store => {
  const networkUnsubscribe = subscribeToNetworkInformation(store)
  const ionicUnsubscribe = subscribeToIonic(store)
  const onOfflineUnsubscribe =
    ionicUnsubscribe || networkUnsubscribe ? null : subscribeToOnOffline(store)
  return () => {
    if (typeof networkUnsubscribe === 'function') networkUnsubscribe()
    if (typeof ionicUnsubscribe === 'function') ionicUnsubscribe()
    if (typeof onOfflineUnsubscribe === 'function') onOfflineUnsubscribe()
  }
}

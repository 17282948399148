import * as actions from './portal.actions'
import * as mutations from './portal.mutations'
import * as getters from './portal.getters'

import { addReset } from '../utils'

const state = {
  byId: {},
  listOfIds: [],
  areFetched: false,
}

export default addReset({
  state,
  actions,
  mutations,
  getters,
})

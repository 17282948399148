import { mapValues } from 'lodash'
import { removePrefixAndToCamelCase } from '@/utils/object'

export const convertToJs = httpEntity => {
  const flags = mapValues(
    removePrefixAndToCamelCase(httpEntity, 'can_portal_', [
      'can_portal_access',
      'can_portal_customize',
      'can_portal_layers_manage',
      'can_portal_manage',
      'can_portal_projects_manage',
      'can_portal_projects_see',
      'can_portal_tags_manage',
      'can_portal_users_manage',
    ]),
    value => value === true || value === '1'
  )
  const dates = mapValues(
    removePrefixAndToCamelCase(httpEntity, 'can_portal_', [
      'can_portal_access_end_date',
      'can_portal_access_start_date',
    ]),
    value => (value && typeof value === 'string' ? Date.parse(value) : null)
  )
  return {
    ...flags,
    ...dates,
  }
}

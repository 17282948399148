import * as actions from './diagnostic.actions'
import * as getters from './diagnostic.getters'
import * as mutations from './diagnostic.mutations'

const state = {
  persistent: {
    runOnThisComputer: false,
  },
  isRunning: false,
  shouldRunSilently: false,
}

export default {
  state,
  actions,
  mutations: mutations,
  getters: getters,
}

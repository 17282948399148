/**
 * @see https://next.vuex.vuejs.org/
 * @see https://ionicframework.com/blog/managing-state-in-vue-with-vuex/
 * @see https://stackoverflow.com/questions/69043413/ionic-storage-with-vue
 */

import { createStore } from 'vuex'

import { vuexStorage } from '@/plugins/storage'
import { networkPlugin } from '@/plugins/network'
import { offlineSyncPlugin } from '@/plugins/offline-sync'

import portal from './portal'
import user from './user'
import project from './project'
import auth from './auth'
import settings from './settings'
import personalNote from './personal-note'
import layers from './layers'
import offline from './offline'
import activities from './activities'
import help from './help'
import viewer from './viewer'
import annotations from './annotations'
import media from './media'
import workState from './work-state'
import diagnostic from './diagnostic'
import backgroundFetch from './fetch-background'
import foregroundFetch from './fetch-foreground'

export const store = createStore({
  actions: {
    clearAll({ commit }) {
      commit('reset')
    },
  },
  modules: {
    auth,
    portal,
    user,
    project,
    settings,
    personalNote,
    layers,
    offline,
    activities,
    help,
    viewer,
    annotations,
    media,
    workState,
    diagnostic,
    backgroundFetch,
    foregroundFetch,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [networkPlugin, vuexStorage, offlineSyncPlugin],
})

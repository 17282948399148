import * as mutations from './offline.mutations'
import * as getters from './offline.getters'
import * as actions from './offline.actions'

import { addReset } from '../utils'

// https://developer.mozilla.org/en-US/docs/Web/API/Storage_API

const state = () => ({
  networkInfo: {},
  storage: {},
  byProjectId: {},
  byLayerId: {},
  offlineStatusByProjectId: {},
})

export default addReset({
  state,
  actions,
  mutations,
  getters,
})

import { addReset } from '../utils'
import * as actions from './media.actions'
import * as getters from './media.getters'
import * as mutations from './media.mutations'

const state = {
  byId: {},
  idToObjectURLs: {},
  pendingMediaIds: [],
  pendingIdToFinalId: {},
}

export default addReset({
  state,
  mutations,
  actions,
  getters,
})

import describeRange from './describeRange'
import { isWindow, isInternetExplorer, isEdge } from './browsers'

const isEqual = (a, b) => describeRange(a) === describeRange(b)

// Heuristic: ANGLE is only on Windows, not in IE, and not in Edge, and does not implement line width greater than one.
const isAngleEnabled = getParam =>
  isWindow() &&
  !isInternetExplorer(getParam) &&
  !isEdge(getParam) &&
  isEqual(getParam('ALIASED_LINE_WIDTH_RANGE'), [1, 1])

const isPowerOfTwo = n => n !== 0 && (n & (n - 1)) === 0

// Heuristic: D3D11 backend does not appear to reserve uniforms like the D3D9 backend, e.g.,
// D3D11 may have 1024 uniforms per stage, but D3D9 has 254 and 221.
//
// We could also test for WEBGL_draw_buffers, but many systems do not have it yet
// due to driver bugs, etc.
const getBackend = getParam =>
  isPowerOfTwo(getParam('MAX_VERTEX_UNIFORM_VECTORS')) &&
  isPowerOfTwo(getParam('MAX_FRAGMENT_UNIFORM_VECTORS'))
    ? 'D3D11'
    : 'D3D9'

const getAngle = getParam =>
  isAngleEnabled(getParam) ? `Yes, ${getBackend(getParam)}` : 'No'

export default getAngle

import * as actions from './settings.actions'
import * as mutations from './settings.mutations'
import * as getters from './settings.getters'

const defaultState = {
  language: null,
  local: {
    query: '',
    projects: {
      layout: 'grid',
    },
  },
}

export default {
  state: defaultState,
  actions,
  mutations: {
    ...mutations,
    reset: state => {
      state.local = defaultState.local
    },
  },
  getters,
}

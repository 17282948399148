export const isEdgeChromium = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.includes('edg/') && userAgent.includes('chrom')
}

export const isWindow = () =>
  navigator.platform === 'Win32' || navigator.platform === 'Win64'

export const isInternetExplorer = getParam =>
  getParam('RENDERER') === 'Internet Explorer'

export const isEdge = getParam => getParam('RENDERER') === 'Microsoft Edge'

export const isFirefox = device =>
  device?.browser?.name.toLowerCase() === 'firefox'

const majorVersion = browser => browser.version.split('.')[0]
const minorVersion = browser => browser.version.split('.')[1]

export const getBrowserNiceName = browser =>
  `${browser.name.toLowerCase()} ${majorVersion(browser)}.${minorVersion(
    browser
  )}`

const supportedBrowsers = {
  chrome: 88,
  firefox: 84,
  edge: 88,
  edge_chromium: 88,
}

const findBrowser = browser => supportedBrowsers[browser.name.toLowerCase()]

export const isBrowserSupported = browser => Boolean(findBrowser(browser))

export const isBrowserUpToDate = browser => {
  const supportedVersion = findBrowser(browser)
  const browserVersion = parseInt(majorVersion(browser), 10)

  return supportedVersion ? supportedVersion < browserVersion : false
}

import { merge, values, remove } from 'lodash'
import { safeArray } from '@/utils/array'
import { createWorkStateList } from '@/store/work-state/index'
import { normalizeWorkState } from '@/store/work-state/work-state.utils'

const WorkStateKind = Object.freeze({
  SERVER_OLD: 'old',
  SERVER_NEW: 'new',
  BOTH: 'both',
  LOCAL: 'local',
})

function getProjectWorkStateList(state, projectId) {
  const existing = state.byProjectId[projectId]
  if (existing) return existing
  const added = createWorkStateList(projectId)
  state.byProjectId[projectId] = added
  return added
}

function updatePendingWorkStates(state) {
  state.projectIdsWithPendingWorkStates = values(state.byProjectId)
    .filter(list => list.pendingWorkStateIds?.length > 0)
    .map(list => list.projectId)
}

function updateWorkStateIds(state, projectId) {
  const list = state.byProjectId[projectId]
  if (!list) return
  const ids = new Set(
    [
      list.pendingWorkStateIds,
      list.savedWorkStateIds,
      list.fetchedWorkStateIds,
    ].flat(1)
  )
  list.workStateIds = [...ids]
  return list
}

function addWorkStates(state, projectId, workStates, endReached, kind) {
  const newWorkStates = safeArray(workStates)
  if (newWorkStates.length === 0) return
  const newIds = new Set()
  const statesById = {}
  workStates.forEach(ws => {
    const id = ws.hash
    if (id === null) return
    newIds.add(id)
    statesById[id] = normalizeWorkState(ws)
  })
  const list = getProjectWorkStateList(state, projectId)
  list.byId = merge({}, list.byId, statesById)
  if (kind === WorkStateKind.SERVER_OLD) {
    list.fetchedWorkStateIds.push(...newIds)
  } else if (kind === WorkStateKind.SERVER_NEW) {
    list.fetchedWorkStateIds.splice(0, 0, ...newIds)
  } else if (kind === WorkStateKind.BOTH) {
    list.savedWorkStateIds.splice(0, 0, ...newIds)
  } else if (kind === WorkStateKind.LOCAL) {
    list.pendingWorkStateIds.splice(0, 0, ...newIds)
  } else {
    console.error(`unexpected work state kind: ${kind}`)
  }
  if (endReached && list.fetchedWorkStateIds.length > 0)
    list.oldestId = list.fetchedWorkStateIds.at(-1)
  // remove new fetched ids from `savedWorkStateIds`
  if (kind === WorkStateKind.SERVER_NEW || kind === WorkStateKind.SERVER_OLD) {
    remove(list.savedWorkStateIds, id => newIds.has(id))
  }
  updateWorkStateIds(state, projectId)
  if (kind === WorkStateKind.LOCAL) updatePendingWorkStates(state)
}

export const didSavePendingWorkState = (state, { projectId, workStateId }) => {
  const list = getProjectWorkStateList(state, projectId)
  const pending = list?.pendingWorkStateIds
  if (!pending) return
  const idx = pending.lastIndexOf(workStateId)
  const lastIndex = pending.length - 1
  if (idx !== lastIndex) {
    console.warn(
      `[UNEXPECTED] saved work state ${workStateId} is not the oldest pending ws`
    )
    if (idx < 0) return
  }
  list.savedWorkStateIds.splice(0, 0, workStateId)
  list.pendingWorkStateIds.splice(idx, 1)
  if (idx !== lastIndex) updateWorkStateIds(state, projectId)
  updatePendingWorkStates(state)
}

export const insertSavedWorkState = (state, { projectId, workState }) => {
  addWorkStates(state, projectId, [workState], false, WorkStateKind.BOTH)
}

export const insertPendingWorkState = (state, { projectId, workState }) =>
  addWorkStates(state, projectId, [workState], false, WorkStateKind.LOCAL)

export const appendFetchedWorkStates = (
  state,
  { projectId, workStates, endReached }
) => {
  addWorkStates(
    state,
    projectId,
    workStates,
    endReached,
    WorkStateKind.SERVER_OLD
  )
}

export const insertFetchedWorkStates = (
  state,
  { projectId, workStates, endReached }
) => {
  addWorkStates(
    state,
    projectId,
    workStates,
    endReached,
    WorkStateKind.SERVER_NEW
  )
}

export const selectWorkStateId = (state, { projectId, workStateId }) => {
  const list = getProjectWorkStateList(state, projectId)
  const ws = list.byId ? list.byId[workStateId] : null
  list.currentDefinition = null
  list.selected = {
    id: ws ? workStateId : '',
    definition: ws,
  }
}

export const setCurrentWorkState = (state, { projectId, workState }) => {
  const list = getProjectWorkStateList(state, projectId)
  if (!list || !workState) return
  const ws = normalizeWorkState(workState)
  const selected = list.selected?.id ? list.byId[list.selected.id] : null
  if (ws === selected) {
    list.currentDefinition = null
    list.selected.definition = selected
  } else {
    list.selected.definition = null
    list.currentDefinition = ws
  }
}

import { addReset } from '../utils'
import * as actions from './layers.actions'
import * as getters from './layers.getters'
import * as mutations from './layers.mutations'

const state = {
  byId: {},
  listOfIdsByProjectId: {},
}

export default addReset({
  state,
  mutations,
  actions,
  getters,
})

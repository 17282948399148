import { createI18n } from 'vue-i18n'

/**
 * - [Tutorial](https://vue-i18n.intlify.dev/guide/#javascript)
 * - [API](https://vue-i18n.intlify.dev/api/composition.html)
 * - https://itnext.io/setting-language-with-i18n-by-os-language-in-vue-router-vuex-e42c9318c9ec
 */

const loadLocaleMessages = () => {
  const locales = require.context(
    '@/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )

  return locales
    .keys()
    .map(key => ({ key, match: key.match(/([A-Za-z0-9-_]+)\./i) }))
    .filter(l => l.match && l.match.length > 1)
    .reduce(
      (acc, { key, match }) => ({
        ...acc,
        [match[1]]: locales(key),
      }),
      {}
    )
}

export const i18n = createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: loadLocaleMessages(),
  legacy: false, // Composition API only
  globalInjection: true,
})

import EventEmitter from 'events'
import pendingValue from '@/utils/pendingValue'

import { getServerDomain } from '@/utils/viewer/config'
import {
  convertBackgroundToViewer,
  convertUserToViewer,
  convertProjectAndLayersToViewer,
  convertAnnotationToViewer,
  convertAnnotationFromViewer,
  convertBackgroundFromViewer,
  convertToWorkStateObject,
} from '@/utils/viewer/converters'

// constants used by viewer.js
const CREATE_TAG = 'create_Object_Tag'
const ON_TAG_CREATED = 'create_Object_Tag_End'
const QUIT_ENGINE = 'engine_request_Quit'
const SET_BACKGROUND = 'Engine.Settings.Viewer.Background.value'
const CENTER_VIEW = 'viewer_camera_CenterView'
const LOAD_PROJECT = 'project_Load'
const LOAD_USER_INFO = 'user_Info'
const WORK_STATE_SEND = 'workState_Load'
const WORK_STATE_REQUEST = 'workState_Save'
const ON_WORK_STATE_RESPONSE = 'workState_Save_result'
const SET_OBJECT_VISIBILITY = 'object_Visibility'
const ON_ENGINE_QUIT = 'engine_Quit'
const ON_PROJECT_LOADED = 'project_Loaded'
const START_TOOL_MEASURE = 'tool_Measure_Generic_Start'
// const TOOL_MEASURE_STOPPED = 'tool_Measure_Generic_End'
const OBJECT_ADDED = 'object_Added'
const REMOVE_OBJECT = 'object_Remove'

// events sent by ViewerProxy
export const ViewerEvent = Object.freeze({
  ANNOTATION_PLACED: 'annotation-placed',
  BACKGROUND_CHANGED: 'background-changed',
  MEASURE_TOOL_STOPPED: 'measure-tool-stopped',
  LOADED: 'loaded',
  STOPPED: 'stopped',
  ANNOTATION_ADDED: 'annotation-added',
  LAYER_ADDED: 'layer-added',
})

export class ViewerProxy extends EventEmitter {
  constructor(rme, containerId, user) {
    super()
    const domain = getServerDomain()
    this.ready = false
    this.loading = false
    this.waitingToLoad = null
    this.quitting = false
    this.waitingToQuit = false
    this.queuedCommands = []
    this.userInfo = convertUserToViewer(user)
    this.msg(`create Engine @${containerId}`)
    this.engine = new rme.Engine(domain, true, containerId)
    this.engine.sendBind(msg => this.listener(msg))
  }

  // <editor-fold desc="engine life cycle">

  async planToLoad(projectInfo) {
    this.queuedCommands = []
    this.waitingToQuit = false
    if (this.loading || this.quitting || this.waitingToQuit) {
      this.waitingToLoad = projectInfo
    } else {
      this.loading = true
      this.waitingToLoad = null
      await this.sendLoadCommands(projectInfo)
    }
  }

  async planToQuit() {
    if (this.quitting || this.waitingToQuit) return
    this.queuedCommands = []
    this.waitingToLoad = null
    if (this.ready) {
      this.ready = false
      this.quitting = true
      await this.sendCommand(QUIT_ENGINE)
    } else {
      this.waitingToQuit = true
    }
  }

  async planToLoadPending() {
    const pending = this.waitingToLoad
    if (!pending) return
    this.ready = false
    this.loading = true
    this.waitingToLoad = null
    await this.sendLoadCommands(pending)
  }

  async sendLoadCommands(projectInfo) {
    this.log('sendLoadCommands', this.userInfo, projectInfo)
    await this.sendCommand(LOAD_USER_INFO, this.userInfo)
    await this.sendCommand(LOAD_PROJECT, projectInfo)
  }

  async engineDidQuit() {
    this.ready = false
    this.quitting = false
    this.waitingToQuit = false
    this.emit(ViewerEvent.STOPPED)
    if (this.waitingToLoad != null) {
      await this.planToLoadPending()
    }
  }

  async engineDidLoadProject() {
    this.ready = true
    this.loading = false
    if (this.waitingToQuit) {
      this.queuedCommands = []
      this.quitting = true
      await this.sendCommand(QUIT_ENGINE)
    } else if (this.waitingToLoad != null) {
      await this.planToLoadPending()
    } else {
      await this.executeQueuedCommands()
      this.emit(ViewerEvent.LOADED)
    }
  }

  async executeQueuedCommands() {
    this.log('executeQueuedCommands', this.queuedCommands)
    while (this.queuedCommands?.length > 0) {
      const fn = this.queuedCommands[0]
      this.queuedCommands.splice(0, 1)
      await Promise.resolve(fn())
    }
  }

  async executeOrQueue(fn) {
    console.assert(typeof fn === 'function')
    if (this.loading || this.waitingToLoad || this.queuedCommands.length > 0) {
      this.queuedCommands.push(fn)
    } else if (this.ready) {
      console.warn(this.msg('ignoring command with no loaded project'), fn)
      return await Promise.resolve(fn())
    }
  }

  // </editor-fold>

  // <editor-fold desc="private methods">

  async listener(msg) {
    if (typeof msg !== 'object' || typeof msg.name !== 'string') return
    const { name, value } = msg
    switch (name) {
      case ON_ENGINE_QUIT:
        return this.engineDidQuit()
      case ON_PROJECT_LOADED:
        return this.engineDidLoadProject()
      case ON_WORK_STATE_RESPONSE:
        if (this.workStateResolve) this.workStateResolve(value)
        break
      case ON_TAG_CREATED:
        console.log('tag created', name, value)
        return this.emit(
          ViewerEvent.ANNOTATION_PLACED,
          convertAnnotationFromViewer(value)
        )
      case SET_BACKGROUND:
        return this.emit(
          ViewerEvent.BACKGROUND_CHANGED,
          convertBackgroundFromViewer(value)
        )
      // case TOOL_MEASURE_STOPPED: // is not triggered as the good moment
      //   this.emit(MEASURE_TOOL_STOPPED)
      //   break
      case OBJECT_ADDED:
        this.log('object added', name, value)

        if (value.Tag) {
          this.emit(ViewerEvent.ANNOTATION_ADDED, {
            viewerId: value.id,
            viewerAnnotation: value.Tag,
          })
        } else if (value.PointCloud) {
          this.emit(ViewerEvent.LAYER_ADDED, {
            viewerId: value.id,
            viewerLayer: value.PointCloud,
          })
        } else if (value.Bim) {
          this.emit(ViewerEvent.LAYER_ADDED, {
            viewerId: value.id,
            viewerLayer: value.Bim,
          })
        } else if (value.Dao) {
          this.emit(ViewerEvent.LAYER_ADDED, {
            viewerId: value.id,
            viewerLayer: value.Dao,
          })
        } else if (value.Shapefile) {
          this.emit(ViewerEvent.LAYER_ADDED, {
            viewerId: value.id,
            viewerLayer: value.Shapefile,
          })
        }

        break
    }
  }

  async sendCommand(name, param = null) {
    if (!this.engine) return
    return await this.engine.receive('signal', name, param)
  }

  async sendVariable(name, value) {
    if (!this.engine) return
    return await this.engine.receive('variable', name, value)
  }

  status() {
    if (this.ready) return ''
    else if (this.waitingToQuit) return 'waitQuit'
    else if (this.waitingToLoad) return 'waitLoad'
    else if (this.loading) return 'loading...'
    else if (this.quitting) return 'quitting...'
    return '?'
  }

  msg(content) {
    return `[VIEWER_PROXY] ${this.status()} ${content}`
  }

  log(content, ...args) {
    console.log(this.msg(content), ...args)
  }

  // </editor-fold>

  // <editor-fold desc="public methods (called by Viewer.vue)"

  async load({ project, layers, annotations }) {
    const projectInfo = convertProjectAndLayersToViewer(
      project,
      layers,
      annotations
    )
    return await this.planToLoad(projectInfo)
  }

  async stop() {
    return await this.planToQuit()
  }

  async requestWorkState() {
    this.log('requestWorkState')
    if (!this.ready || this.workStateResolve != null) return null
    const { promise, resolve } = pendingValue({ timeout: 3000 })
    this.workStateResolve = resolve
    await this.sendCommand(WORK_STATE_REQUEST)
    try {
      return await promise
    } finally {
      this.workStateResolve = null
    }
  }

  async clearWorkState() {
    this.log('clearWorkState')
    this.executeOrQueue(
      async () => await this.sendCommand(WORK_STATE_SEND, 'null')
    )
  }

  async sendWorkState(input) {
    const ws = input == null ? 'null' : convertToWorkStateObject(input)
    this.log('sendWorkState', ws)
    if (ws == null) return
    await this.executeOrQueue(async () => {
      await this.sendCommand(WORK_STATE_SEND, ws)
      // hack - if cesium is not available,
      // the viewer sometimes does not send background change notification,
      // so we extract the value from the work state
      const cesium = typeof ws === 'object' ? ws?.CesiumViewer?.enable : null
      if (cesium == null) {
        const bg = ws.PointCloudViewer?.settings?.background || ''
        return this.emit(
          'background-changed',
          convertBackgroundFromViewer(cesium ? 'null' : bg)
        )
      }
    })
  }

  async setBackground(background) {
    const viewerBackground = convertBackgroundToViewer(background)
    this.log('setBackground', viewerBackground)
    await this.executeOrQueue(
      async () => await this.sendVariable(SET_BACKGROUND, viewerBackground)
    )
  }

  async addAnnotation(annotation) {
    const viewerAnnotation = convertAnnotationToViewer(annotation)
    this.log('addAnnotation', viewerAnnotation)
    if (this.ready) await this.sendCommand(CREATE_TAG, viewerAnnotation)
  }

  async recenterView() {
    this.log('recenterView')
    if (this.ready) await this.sendCommand(CENTER_VIEW)
  }

  async startMeasureTool(toolId) {
    this.log('startMeasureTool')
    await this.sendCommand(START_TOOL_MEASURE, toolId)
  }

  async setVisibility(id, visible) {
    await this.sendCommand(SET_OBJECT_VISIBILITY, {
      id,
      value: visible,
    })
  }

  async removeObject(id) {
    await this.sendCommand(REMOVE_OBJECT, id)
  }

  // </editor-fold>
}

import * as actions from './work-state.actions'
import * as mutations from './work-state.mutations'
import * as getters from './work-state.getters'

import { addReset } from '../utils'

// The dependencies between the four arrays of work state ids described below
// are managed in the mutations to ensure the state is always consistent.
// The goal of having these interrelated arrays is to support the creation of work states
// while offline, allow sync of server & local data, and to ensure the order of work states
// in Vuex store is the same as the order on the server side.
export function createWorkStateList(projectId) {
  return {
    projectId: projectId,
    // all known ids from most recent to older
    // this array is the concatenation of the following three arrays
    workStateIds: [],
    // ids actually retrieved from server, from most recent to older
    // the order cannot change but there may be old ids that we have not fetched yet (they would be appended at the end of the array)
    // also, new ids can be created on the server (such ids would be inserted at the start of the array)
    // when fetching work state ids, we know we can stop when we find `fetchedWorkStateIds[0]`
    fetchedWorkStateIds: [],
    // ids saved to the server, but not fetched (yet), from most recent to older
    // we know we don't need to save them again, and their relative order is correct
    // however, there may be either ids on the server in between
    // when fetching new work state ids (to fill `fetchedWorkStateIds`), we remove the returned ids from this array
    savedWorkStateIds: [],
    // ids defined locally only, not saved to the server yet, from most recent to older
    // when this array is not empty, we attempt to send the work state to the server
    // after a work state is successfully saved to the server, we remove its id from this array and append it to `savedWorkStateIds`
    pendingWorkStateIds: [],
    // the work state definition, saved as properties (where the property name is the work state id)
    byId: {},
    // the oldest id on the server
    // if `oldestId` is falsy, it means we did not retrieve all work states from the server
    // (only the `fetchedWorkStateIds.length` most recent ones)
    oldestId: '',
    selected: { id: '', definition: '' },
    currentDefinition: '',
  }
}

const state = () => ({
  byProjectId: {},
  projectIdsWithPendingWorkStates: [],
})

export default addReset({
  state,
  actions,
  getters,
  mutations,
})

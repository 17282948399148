import { values } from 'lodash'
import { safeArray } from '@/utils/array'
import { OfflineItemStatus } from '@/store/offline/offline.utils'
import { isPhotoAnnotation } from '@/utils/annotations'
import {
  getProjectCoverUrl,
  getProjectLargeCoverUrl,
  getUserAvatarUrl,
} from '@/utils/links.js'
import pendingValue from '@/utils/pendingValue.js'

const fetchIdForLayer = layer => {
  switch (layer.type) {
    case 'pointcloud':
    case 'mesh':
    case 'bim':
      return `layer_${layer.type}_${layer.id}`
    default:
      return null
  }
}

const fetchIdForProjectAnnotations = projectId => `${projectId}_annotations`

const fetchIdForProjectImages = projectId => `${projectId}_images`

function startDownloadingUrlsForLayers(
  { getters, dispatch },
  { projectId, layerId, notify, batchSize },
  layerToUrlArrayFunction
) {
  const layer = getters.getLayerById(layerId)
  const urls = layer ? layerToUrlArrayFunction(layer) : null
  if (!urls) return
  dispatch('beginForegroundFetchForRequests', {
    id: fetchIdForLayer(layer),
    name: layer.name,
    projectId,
    requests: urls,
    batchSize,
    notify,
  })
  return urls
}

function startDownloadingUrlsFromFileForLayers(
  { getters, dispatch },
  { projectId, layerId, notify, batchSize },
  layerToFileUrl
) {
  const layer = getters.getLayerById(layerId)
  const url = layer ? layerToFileUrl(layer) : null
  if (!url) return
  dispatch('beginForegroundFetchForUrlList', {
    id: fetchIdForLayer(layer),
    name: layer.name,
    projectId,
    url,
    batchSize,
    notify,
  })
  return url
}

function getProjectStatusFromOfflineInfo(info) {
  switch (info?.status) {
    case OfflineItemStatus.yes:
    case OfflineItemStatus.partial:
      return 'ready'
    case OfflineItemStatus.pending:
    case OfflineItemStatus.downloading:
      return 'inProgress'
    default:
      return null
  }
}

function startDownloadingLayer(context, { projectId, layer, batchSize }) {
  const { commit, dispatch } = context
  const layerId = layer.id
  const params = {
    layerId,
    projectId,
    batchSize,
    notify: fetch => {
      commit('updateProjectsAfterLayerFetchProgress', { layerId, fetch })
      const info = context.state.byProjectId[projectId]
      const status = getProjectStatusFromOfflineInfo(info)
      commit('setProjectOfflineStatus', { projectId, status })
      if (fetch?.inProgress === false)
        dispatch('updateStorageInfo').then(info =>
          console.log(`fetch ${fetch.id} done`, info)
        )
    },
  }
  switch (layer.type) {
    case 'pointcloud':
      return startDownloadingUrlsFromFileForLayers(
        context,
        params,
        layer => layer.url + '/index.txt'
      )
    case 'mesh':
      return startDownloadingUrlsForLayers(context, params, layer => [
        layer.url + '/mesh.nxz',
      ])
    case 'bim':
      return startDownloadingUrlsForLayers(context, params, layer => [
        layer.url + '/model.ifc',
        'https://v2.rimnat.com/libs/internal/viewer/build/web-ifc.wasm',
      ])
    default:
      console.error(
        `offline mode for layers of type ${layer.type} is not supported yet`
      )
      return
  }
}

async function startDownloadingProjectLayers(context, { projectId }) {
  const { dispatch, commit } = context
  const layers = await dispatch('fetchLayersForProject', { projectId })
  commit('initProjectOffline', { projectId, layers })
  commit('setProjectOfflineStatus', { projectId, status: 'inProgress' })
  const batchSize = layers.length > 2 ? 50 / (layers.length - 2) : 32
  layers.forEach(layer =>
    startDownloadingLayer(context, {
      projectId,
      layer,
      batchSize,
    })
  )
}

async function startDownloadingProjectAnnotations(
  { commit, dispatch },
  { projectId }
) {
  // ensure we have data for all annotations in Vuex store
  const annotations = await dispatch('fetchAnnotationsForProject', {
    projectId,
  })
  // ensure we have media data, so we have the URLs for image annotations
  // (we currently ignore media ids for annotations of type other than photo)
  const photoAnnotations = safeArray(annotations).filter(isPhotoAnnotation)
  if (photoAnnotations.length === 0) return
  const notify = fetch =>
    commit('updateProjectsAfterAnnotationsFetchProgress', { projectId, fetch })
  const results = await Promise.allSettled(
    photoAnnotations.map(async a => await dispatch('fetchMedia', a.mediaId))
  )
  const photoURLs = safeArray(results)
    .filter(p => p.status === 'fulfilled')
    .map(p => p.value.url)
  if (photoURLs.length > 0)
    dispatch('beginForegroundFetchForRequests', {
      id: fetchIdForProjectAnnotations(projectId),
      requests: photoURLs,
      notify,
    })
}

async function startDownloadingProjectImages({ dispatch }, { projectId }) {
  // we consider these images to be optional, and we do not need to
  // track the download through the 'notify' parameter
  const imageURLs = [
    getProjectCoverUrl(projectId),
    getProjectLargeCoverUrl(projectId),
    getProjectCoverUrl(undefined),
    getProjectLargeCoverUrl(undefined),
    getUserAvatarUrl(undefined),
  ]
  dispatch('beginForegroundFetchForRequests', {
    id: fetchIdForProjectImages(projectId),
    requests: imageURLs,
    mode: 'no-cors',
  })
}

function fetchNeededProjectProperties({ dispatch }, { projectId }) {
  // ensure we have all project properties
  dispatch('fetchProject', projectId)
  // ensure we have project members (excluding their avatar)
  dispatch('fetchProjectUsers', { projectId })
  // ensure we have all portal data
  dispatch('fetchPortalList')
}

function arrayIsSingleton(array, value) {
  return Array.isArray(array) && array.length === 1 && array[0] === value
}

export const discardLayerOfflineData = async () => {}

export const startRetrievingProjectOfflineData = async (
  context,
  { projectId }
) => {
  const { dispatch } = context
  fetchNeededProjectProperties(context, { projectId })
  await Promise.allSettled([
    dispatch('requestPersistentStorage'),
    startDownloadingProjectLayers(context, { projectId }),
    startDownloadingProjectAnnotations(context, { projectId }),
    startDownloadingProjectImages(context, { projectId }),
  ])
}

export const abortProjectOfflineDataRetrieval = async (
  { state, getters, dispatch },
  { projectId, keepShared = true } = {}
) => {
  const projectInfo = state.byProjectId[projectId]
  if (!projectInfo) return
  const layerIdToFetchId = {}
  safeArray(getters.getLayersByProjectId(projectId)).forEach(
    layer => (layerIdToFetchId[layer.id] = fetchIdForLayer(layer))
  )
  const abortLayer = keepShared
    ? layerInfo => arrayIsSingleton(layerInfo?.usedByProjectIds, projectId)
    : () => true
  const fetchIdsToAbort = values(projectInfo.layers)
    .filter(abortLayer)
    .map(layerInfo => layerInfo.layerId)
    .map(layerId => getters.getLayerById(layerId))
    .map(fetchIdForLayer)
  fetchIdsToAbort.push(fetchIdForProjectAnnotations(projectId))
  dispatch('abortForegroundFetches', { fetchIds: fetchIdsToAbort })
}

export const discardProjectOfflineData = async (
  { getters, dispatch, commit },
  { projectId }
) => {
  const layers = getters.getLayersByProjectId(projectId)
  const fetchIds = safeArray(layers).map(fetchIdForLayer)
  fetchIds.push(fetchIdForProjectAnnotations(projectId))
  if (fetchIds) dispatch('clearForegroundFetches', { ids: fetchIds })
  commit('deleteProjectOffline', { projectId })
  commit('clearProjectOfflineStatus', { projectId })
}

export const discardAllOfflineData = async ({ dispatch, commit }) => {
  commit('clearAllProjectsOfflineStatus')
  commit('deleteAllOffline')
  await dispatch('abortForegroundFetches')
  dispatch('clearForegroundFetches').then(() => dispatch('updateStorageInfo'))
  dispatch('updateStorageInfo')
}

export const requestPersistentStorage = async ({ dispatch }) => {
  const persistent = navigator.storage?.persisted
    ? await navigator.storage.persisted()
    : false
  if (!persistent && navigator.storage?.persist) {
    await askNotificationPermission()
    await navigator.storage.persist()
  }
  return await dispatch('updateStorageInfo')
}

export const updateStorageInfo = async ({ commit }) => {
  const storage = navigator.storage
  const info = {}
  if (storage?.persisted) {
    info.persistent = await storage.persisted()
  }
  if (storage?.estimate) {
    const p = await storage.estimate()
    info.quota = p?.quota
    info.usage = p?.usage
  }
  commit('setStorageInfo', info)
  return info
}

// https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API#:~:text=Feature%2Ddetecting%20the%20requestPermission()%20promise
function checkNotificationPromise() {
  try {
    Notification.requestPermission().then()
  } catch (e) {
    return false
  }
  return true
}

async function askNotificationPermission() {
  if ('Notification' in window) {
    if (window.Notification.permission !== 'default')
      return window.Notification.permission
    if (checkNotificationPromise()) {
      return await window.Notification.requestPermission()
    } else {
      const { promise, resolve } = pendingValue(60000)
      Notification.requestPermission(p => resolve(p))
      try {
        return await promise
      } catch {
        return 'default'
      }
    }
  }
}

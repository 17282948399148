import { afterLogoutRoute } from '@/plugins/router'
import { buildDeepLink } from '@/utils/links'

import { addReset } from '../utils'
/**
 * =========================== /!\ ======================
 * this module should be removed since storing token is not a best practice
 *
 * - https://auth0.com/docs/security/data-security/token-storage
 */

function setToken(state, authResult) {
  const { idToken, idTokenPayload } = authResult
  state.idToken = idToken
  state.idTokenPayload = idTokenPayload
  state.authResult = authResult
}

function refreshApi({ getters }) {
  this.$api.updateAuthorization(getters.idToken)
}

async function logout({ dispatch }) {
  dispatch('clearAll')
  const returnTo = await buildDeepLink(afterLogoutRoute?.path)
  // https://auth0.com/docs/libraries/auth0-single-page-app-sdk#logout
  this.$auth.logout({ returnTo })
}

async function checkSession({ getters, commit }) {
  if (getters.authResult?.idTokenPayload) {
    const { nonce } = getters.authResult?.idTokenPayload
    try {
      const newAuthResult = this.$auth.renewTokens({ nonce })
      commit('setToken', newAuthResult)
    } catch (error) {
      console.error('checkSession.error', error)
    }
  }
}

export default addReset({
  state: {
    idToken: '',
    idTokenPayload: null,
    authResult: null,
  },
  actions: {
    refreshApi,
    logout,
    checkSession,
  },
  mutations: {
    setToken,
  },
  getters: {
    idTokenPayload: state => state.idTokenPayload,
    idToken: state => state.idToken,
    avatar: state => state.idTokenPayload?.picture,
    isAuthenticated: state => Boolean(state.idToken),
    emailAuthenticated: state => state.idTokenPayload?.email,
    authResult: state => state.authResult,
  },
})

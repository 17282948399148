// TODO shoud be removed
export const ViewerStatus = {
  DEFAULT: 'DEFAULT',
  ANNOTATION_PLACEMENT: 'ANNOTATION_PLACEMENT',
  ANNOTATION_EDITION: 'ANNOTATION_EDITION',
}

export const isAnnotation = status =>
  status === ViewerStatus.ANNOTATION_PLACEMENT ||
  status === ViewerStatus.ANNOTATION_EDITION

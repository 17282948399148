import { convertToJs } from '@/utils/modelConvertors/layers.converter'

export async function fetchLayersForProject({ commit }, { projectId }) {
  const options = {
    params: {
      project_id: projectId,
      // server_id: 3,
    },
  }

  const url = '/project/getProjectLayersWithDetails'

  const { data } = await this.$api.get(url, options)

  if (!data || typeof data.map !== 'function') {
    return
  }

  const layers = data.map(convertToJs)
  commit('setLayers', { layers, projectId })
  return layers
}

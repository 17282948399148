const types = {
  text: {
    toHttp: 'tagTypeText',
    fromHttp: 'texte',
  },
  url: {
    toHttp: 'tagTypeUrl',
    fromHttp: 'web',
  },
  photo: {
    toHttp: 'tagTypePhoto',
    fromHttp: 'photo',
  },
  realtime: {
    toHttp: 'tagTypeRealtime',
    fromHttp: 'realtime',
  },
}

export const convertTypeToHttpToCreate = type =>
  types[type]?.toHttp || types.text.toHttp

export const convertToHttpToCreate = annotation => {
  const {
    placeholder,
    // eslint-disable-next-line no-unused-vars
    projectId,
    mediaId,
    camera,
    target,
    ...restOfAnnotation
  } = annotation

  console.log('convertToHttpToCreate', annotation)

  return {
    ...restOfAnnotation,
    media_id: mediaId,
    annotation: [placeholder.x, placeholder.y, placeholder.z],
    camera: [camera.x, camera.y, camera.z],
    target: [target.x, target.y, target.z],
    type: convertTypeToHttpToCreate(annotation.type),
  }
}

const convertTypeFromHttp = type =>
  Object.keys(types).find(t => types[t].fromHttp === type) || types.text

export const convertToJs = httpAnnotation => ({
  id: httpAnnotation.tag_id,
  projectId: httpAnnotation.project_id,
  type: convertTypeFromHttp(httpAnnotation.type),
  title: decodeURIComponent(httpAnnotation.placeholder),
  placeholder: {
    x: httpAnnotation.placeholder_x,
    y: httpAnnotation.placeholder_y,
    z: httpAnnotation.placeholder_z,
  },
  description: decodeURIComponent(httpAnnotation.description),
  group: decodeURIComponent(httpAnnotation.groupe),
  color: httpAnnotation.color,
  camera: {
    x: httpAnnotation.camera_x,
    y: httpAnnotation.camera_y,
    z: httpAnnotation.camera_z,
  },
  target: {
    x: httpAnnotation.target_x,
    y: httpAnnotation.target_y,
    z: httpAnnotation.target_z,
  },
  url: decodeURIComponent(httpAnnotation.url),
  date: new Date(parseInt(httpAnnotation.date, 10)),
  mediaId: parseInt(httpAnnotation.media_id, 10),
  raw: httpAnnotation,
})

const convertTypeToHttp = type => types[type]?.fromHttp || types.text.fromHttp

export const convertToHttp = annotation => ({
  tag_id: annotation.id,
  project_id: annotation.projectId,
  type: convertTypeToHttp(annotation.type),
  placeholder: annotation.title,
  description: annotation.description,
  groupe: annotation.group,
  camera_x: annotation.camera.x,
  camera_y: annotation.camera.y,
  camera_z: annotation.camera.z,
  target_x: annotation.target.x,
  target_y: annotation.target.y,
  target_z: annotation.target.z,
  color: annotation.color,
  url: encodeURIComponent(annotation.url),
  media_id: annotation.mediaId,
  placeholder_x: annotation.placeholder.x,
  placeholder_y: annotation.placeholder.y,
  placeholder_z: annotation.placeholder.z,
})

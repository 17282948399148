import { mapValues, snakeCase } from 'lodash'
import { removePrefixAndToCamelCase } from '@/utils/object'

// permissions are found on 'users' and 'projects'
export const convertToJs = httpEntity => {
  const flags = mapValues(
    removePrefixAndToCamelCase(httpEntity, 'can_project_', [
      'can_project_access',
      'can_project_chat_moderate',
      'can_project_chat_read',
      'can_project_chat_write',
      'can_project_customize',
      'can_project_layers_manage',
      'can_project_model_see',
      'can_project_shares_manage',
      'can_project_shares_see',
      'can_project_shares_send',
      'can_project_tags_add',
      'can_project_tags_manage',
      'can_project_tags_see',
      'can_project_tools_use',
      'can_project_users_manage',
      'can_project_users_see',
    ]),
    value => value === true || value === '1'
  )
  const dates = mapValues(
    removePrefixAndToCamelCase(httpEntity, 'can_project_', [
      'can_project_access_end_date',
      'can_project_access_start_date',
    ]),
    value => (value && typeof value === 'string' ? Date.parse(value) : null)
  )
  return {
    userId: httpEntity.user_id,
    projectId: httpEntity.project_id,
    ...flags,
    access:
      flags.access ||
      httpEntity.user_accessible === true ||
      httpEntity.user_accessible === '1' ||
      httpEntity.project_status === 'public',
    ...dates,
  }
}

export const convertJsNameToRimnat = name =>
  'can_project_' + snakeCase(name).toLowerCase()

import { isFirefox } from './browsers'

const getConnection = () =>
  navigator.connection || navigator.mozConnection || navigator.webkitConnection

const evaluateQuality = (downlink, effectiveType) => {
  const online = navigator.onLine

  if (online == false || downlink == 0) {
    return 0
  }
  if (effectiveType == '2g') {
    return 5
  }
  if (effectiveType == '3g') {
    return 15
  }
  if (effectiveType == '4g' && downlink > 5) {
    return 90
  }
  if (effectiveType == '4g' && downlink > 4) {
    return 70
  }
  if (effectiveType == '4g' && downlink > 3) {
    return 50
  }
  return 30
}

const speedTest = device => {
  if (isFirefox(device)) {
    return null
  }

  const { effectiveType, downlink } = getConnection()
  const downloadLink = parseFloat(downlink, 10)

  return {
    quality: evaluateQuality(downloadLink, effectiveType),
    downlink: downloadLink,
  }
}

export default speedTest

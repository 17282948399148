export const SaveWorkStateResult = Object.freeze({
  success: 'success',
  pending: 'pending',
  errorSameAsLatest: 'errorSameAsLatest',
  errorServer: 'errorServer',
})

export const normalizeWorkState = ws => {
  if (ws == null || typeof ws !== 'object') return null
  if (ws.hash) delete ws.hash
  return JSON.stringify(ws, (_, value) =>
    typeof value === 'number' ? parseFloat(value.toFixed(8)) : value
  )
}

export const getCacheNameForFetchId = (fetchId, itemCount) => {
  const expected = Number.isInteger(itemCount) && itemCount > 0 ? itemCount : 0
  return `rimnat-${fetchId}-${expected}`
}

export const getCachedFetches = async () => {
  const cacheNames = await window.caches.keys()
  return cacheNames.map(n => checkCacheName(n)).filter(Boolean)
}

export const getCachedFetchesWithSize = async () => {
  const cacheNames = await window.caches.keys()
  const startTime = Date.now()
  const result = []
  let total = 0
  for (const name of cacheNames) {
    const fetch = await cacheWithSize(name)
    if (!fetch) continue
    result.push(fetch)
    total += fetch.length || 0
  }
  const duration = Date.now() - startTime
  console.log(
    `${total} items found in ${result.length} caches | ${duration} ms`
  )
  return result
}

const cacheWithSize = async name => {
  const fetch = checkCacheName(name)
  if (!fetch) return
  try {
    const cache = await window.caches.open(name)
    const keys = await cache.keys()
    if (keys) fetch.length = keys.length
  } catch (error) {
    console.error(error)
  }
  return fetch
}

const cacheNameRegex = /^rimnat-(?<fetchId>.+)-(?<itemCount>\d+)?$/

const checkCacheName = cacheName => {
  const match = cacheName.match(cacheNameRegex)
  if (match) return { cacheName, ...match.groups }
}

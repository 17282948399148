import { createDefaultViewerState } from '@/utils/viewer'
import { ViewerObjectTypes } from './viewer.utils'

export const viewerStatus = state => state.status

export const viewerStateById = state => projectId =>
  state.byProjectId[projectId] || createDefaultViewerState()

export const layerState = state => (projectId, id) =>
  state.byProjectId[projectId]?.[ViewerObjectTypes.LAYERS]?.[id]

export const annotationState = state => (projectId, id) =>
  state.byProjectId[projectId]?.[ViewerObjectTypes.ANNOTATIONS]?.[id]

export const isLayerVisible = state => (projectId, id) =>
  layerState(state)(projectId, id)?.visible

export const isAnnotationVisible = state => (projectId, id) =>
  annotationState(state)(projectId, id)?.visible

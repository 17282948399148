import { merge, omit, uniq } from 'lodash'

const initializeProjectIfNecessary = (state, projectId) => {
  if (!state.byId[projectId]) {
    state.byId[projectId] = { project: null, memberIds: [], isFetched: false }
  }
}

const initializePortal = (state, portalId) => {
  if (!state.byPortalId[portalId]) {
    state.byPortalId[portalId] = { listOfIds: [], areAllFetched: false }
  }
}

export const setProjects = (state, { projects, portalId }) => {
  console.log('setProjects', projects, portalId)
  initializePortal(state, portalId)
  state.byId = omit(state.byId, state.byPortalId[portalId].listOfIds || [])
  const projectsById = {}
  projects.forEach(p => (projectsById[p.id] = { project: p, memberIds: [] }))
  state.byId = merge({}, state.byId, projectsById)
  state.byPortalId[portalId].listOfIds = projects.map(p => p.id)
  state.byPortalId[portalId].areAllFetched = true
}

export const addProject = (state, { project }) => {
  initializeProjectIfNecessary(state, project.id)
  state.byId[project.id].project = project

  initializePortal(state, project.portalId)
  state.byPortalId[project.portalId].listOfIds = uniq([
    ...state.byPortalId[project.portalId].listOfIds,
    project.id,
  ])
}

export const setProjectFetched = (state, { projectId, isFetched }) => {
  initializeProjectIfNecessary(state, projectId)
  state.byId[projectId].isFetched = isFetched
}

export const setProjectMembers = (
  state,
  { projectId, userIds, userPermissions }
) => {
  initializeProjectIfNecessary(state, projectId)
  const project = state.byId[projectId]
  project.memberIds = userIds
  project.memberPermissions = userPermissions
}

export const changeUserPermissionForProject = (
  state,
  { projectId, userId, name, value }
) => {
  const projectPermissions = state.byId[projectId]?.memberPermissions
  const userPermissionsForProject =
    typeof projectPermissions === 'object' ? projectPermissions[userId] : null
  if (userPermissionsForProject === undefined) {
    projectPermissions[userId] = { [name]: value }
  } else if (typeof userPermissionsForProject === 'object') {
    userPermissionsForProject[name] = value
  }
}

export const setProjectCoordinate = (
  state,
  { projectId, coordinateSystem }
) => {
  console.log('setProjectCoordinate', projectId, coordinateSystem)
  const project = state.byId[projectId].project
  if (project) {
    project.coordinateSystem = coordinateSystem
  }
}

export const updateRequestAccess = (state, { project, access }) => {
  const projectToUpdate = state.byId[project?.id]?.project
  if (projectToUpdate) {
    projectToUpdate.access = access
  }
}

import { normalizeWorkState } from '@/store/work-state/work-state.utils'

import { SaveWorkStateResult } from './work-state.utils'

const newWorkStateId = () =>
  Math.random().toString(36).substring(6).toLowerCase().substring(0, 6)

async function postWorkState(
  api,
  { userId, projectId, workStateId, definition }
) {
  const form = new FormData()
  form.append('user_id', userId)
  form.append('project_id', projectId)
  form.append('hash', workStateId)
  form.append('data', definition)
  try {
    const { data } = await api.post('/user/saveUserProjectWork', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data === 'ok'
      ? SaveWorkStateResult.success
      : SaveWorkStateResult.errorServer
  } catch (error) {
    // if we did not get a response from the server, assume its a connectivity issue
    // store work state locally so it can be sync'd later
    return error && error.response == null
      ? SaveWorkStateResult.pending
      : SaveWorkStateResult.errorServer
  }
}

export async function savePendingWorkStates(
  { state, commit, rootGetters },
  { projectId }
) {
  const list = state.byProjectId[projectId]
  const pending = list?.pendingWorkStateIds
  if (!pending) return
  const userId = rootGetters.currentUser?.id
  // copy the array because we will mutate the state during iteration
  for (const workStateId of [...pending].reverse()) {
    const definition = list.byId[workStateId]
    if (!definition) continue
    const result = await postWorkState(this.$api, {
      userId,
      projectId,
      workStateId,
      definition,
    })
    if (result !== SaveWorkStateResult.success) return
    commit('didSavePendingWorkState', { projectId, workStateId })
  }
}

export async function saveWorkState(
  { state, dispatch, commit, rootGetters },
  { projectId, definition }
) {
  if (projectId == null || definition == null) {
    return {}
  }
  const ws = normalizeWorkState(definition)
  const list = await dispatch('updateProjectWorkStateList', {
    projectId,
    maxCount: 1,
  })
  const latestId = list?.workStateIds?.length > 0 ? list.workStateIds[0] : null
  const latestDef = latestId ? list.byId[latestId] : null
  if (latestDef === ws) {
    // the work state we try to create is the same as the most recent one
    // reject the request
    return { result: SaveWorkStateResult.errorSameAsLatest }
  }
  // there may be some pending work states, because the device was previously
  // offline and sync did not occur yet
  // in that case, do not call the API and consider the new work state as pending
  const newId = newWorkStateId()
  const result =
    state.byProjectId[projectId]?.pendingWorkStateIds?.length > 0
      ? SaveWorkStateResult.pending
      : await postWorkState(this.$api, {
          userId: rootGetters.currentUser?.id,
          projectId,
          workStateId: newId,
          definition: ws,
        })
  if (result.startsWith('error')) return { result }
  // mark the work state as 'saved' or 'pending', but not 'fetched' yet
  // it will be moved to 'fetched' when we get newer data from server
  // doing so ensures we maintain correct order even
  // in case of concurrent call to APIs (by different clients)
  const action =
    result === SaveWorkStateResult.success
      ? 'insertSavedWorkState'
      : 'insertPendingWorkState'
  commit(action, {
    projectId,
    workState: { hash: newId, ...definition },
  })
  return { result, workStateId: newId }
}

export async function updateProjectWorkStateList(
  { rootGetters, state, commit },
  { projectId, maxCount = 100, minCount = 0 }
) {
  const userId = rootGetters.currentUser?.id
  const workStates = state.byProjectId[projectId]
  const currentCount = workStates?.fetchedWorkStateIds?.length || 0
  const newWorkStates = []
  const olderWorkStates = []
  const params = {
    user_id: userId,
    project_id: projectId,
    back_step: -1,
  }
  const fetchNextWorkState = async () => {
    try {
      params.back_step += 1
      const { data } = await this.$api.get('/user/getUserProjectWork', {
        params,
      })
      return !data || data === 'null' ? null : data
    } catch (error) {
      return undefined
    }
  }
  let endReached = false
  const mostRecentFetchedState =
    currentCount > 0 ? workStates?.fetchedWorkStateIds[0] : null
  const steps = typeof maxCount === 'number' ? maxCount : 100
  for (let i = 0; i < steps && !endReached; i++) {
    const data = await fetchNextWorkState()
    if (data?.hash === mostRecentFetchedState) break
    if (data) newWorkStates.push(data)
    else endReached = true
  }
  if (!workStates?.oldestId && typeof minCount === 'number' && minCount > 0) {
    const pastSteps = minCount - currentCount - newWorkStates.length
    params.back_step += currentCount
    for (let i = 0; i < pastSteps && !endReached; i++) {
      const data = await fetchNextWorkState()
      if (data) olderWorkStates.push(data)
      else endReached = true
    }
  }
  if (newWorkStates.length > 0)
    commit('insertFetchedWorkStates', {
      projectId,
      workStates: newWorkStates,
      endReached: endReached && olderWorkStates.length === 0,
    })
  if (olderWorkStates.length > 0)
    commit('appendFetchedWorkStates', {
      projectId,
      workStates: olderWorkStates,
      endReached,
    })
  return state.byProjectId[projectId]
}

export async function selectWorkState(
  { dispatch, commit },
  { projectId, workStateId }
) {
  await dispatch('updateProjectWorkStateList', { projectId })
  commit('selectWorkStateId', { projectId, workStateId })
}

export async function selectMostRecentWorkState(
  { dispatch, commit },
  { projectId }
) {
  const workStateList = await dispatch('updateProjectWorkStateList', {
    projectId,
    maxCount: 2,
  })
  if (workStateList?.workStateIds?.length > 0) {
    const workStateId = workStateList.workStateIds[0]
    commit('selectWorkStateId', { projectId, workStateId })
    return workStateId
  }
}

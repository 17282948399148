import config from '@/utils/config'
import { isGeoreferenced } from '@/utils/project'

export const getServerDomain = () => {
  const items = config.serverUrl?.split(':')
  if (!items || items.length < 2) return ''
  const host = items[1]
  let idx = host.lastIndexOf('.')
  if (idx > 0) idx = host.lastIndexOf('.', idx - 1)
  return idx < 0 ? host : host.substring(idx + 1)
}

export const backgroundOptions = project => [
  { value: 'black', label: 'projects.backgrounds.black' },
  { value: 'white', label: 'projects.backgrounds.white' },
  {
    value: 'globe3d',
    label: 'projects.backgrounds.globe3d',
    disabled: !isGeoreferenced(project),
  },
]

export const createDefaultViewerState = () => ({
  background: 'black',
})

<template>
  <ion-app>
    <ion-router-outlet />
    <diagnostic-renderer />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { defineComponent } from 'vue'
import { App } from '@capacitor/app'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { computed } from 'vue'

import { isAuth0Link, isDeepLink, extractPathFromDeepLink } from '@/utils/links'
import pageTitle from '@/utils/pageTitle'
import DiagnosticRenderer from '@/components/atoms/DiagnosticRenderer'

const refreshDocumentTitle = (to, tr) => {
  if (to?.meta?.title) {
    document.title = pageTitle(tr(to?.meta?.title))
  }
}

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    DiagnosticRenderer,
  },
  beforeCreate() {
    // to redirect the url
    App.addListener('appUrlOpen', async ({ url }) => {
      if (isAuth0Link(url)) {
        this.$auth.handleRedirectCallback(url)
      } else if (await isDeepLink(url)) {
        const path = await extractPathFromDeepLink(url)
        this.$router.push({ path })
      }
    })

    // https://stackoverflow.com/questions/51980296/detect-back-button-in-navigation-guards-of-vue-router
    window.popStateDetected = false
    window.addEventListener('popstate', () => {
      window.popStateDetected = true
    })

    this.$store.getters.language().then(l => {
      this.$i18n.locale = l
    })
  },
  provide() {
    // injection is not reactive by default
    // @see https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity

    return {
      project: computed(() => this.project),
      projectId: computed(() => this.projectId),
    }
  },
  async mounted() {
    if (this.idTokenPayload?.email) {
      await this.initializeWhenUserAuthenticated()

      setInterval(() => {
        this.fetchData()
      }, 1000 * 60 * 60 * 1)
    }
    this.setQuery('')
    this.$store.dispatch('examineCacheForForegroundFetches')
    this.$store.dispatch('updateStorageInfo')
  },
  computed: {
    projectId() {
      return this.$route.params?.projectId
    },
    project() {
      return this.projectById(this.projectId)
    },
    ...mapGetters(['projectById', 'idTokenPayload']),
  },
  methods: {
    async initializeWhenUserAuthenticated() {
      await this.fetchData()
      this.$store.dispatch('startDiagnosticIfFirstTime')
    },
    async fetchSelectedProject() {
      const projectId = this.$route.params?.projectId
      const isFetched = this.$store.getters.isProjectFetched(projectId)

      if (!isFetched && projectId) {
        try {
          await this.$store.dispatch('fetchLayersForProject', { projectId })
          await this.$store.dispatch('fetchProject', projectId)
          await this.$store.dispatch('fetchAnnotationsForProject', {
            projectId,
          })
        } catch (error) {
          console.error('error while fetching selected project')
        } finally {
          this.$store.commit('setProjectFetched', {
            projectId,
            isFetched: true,
          })
        }
      }
    },
    async fetchData() {
      this.refreshApi()
      try {
        await this.fetchAuthenticatedUser()
        await this.fetchPortalList()
        await this.fetchSelectedProject()
        console.log('fetchportal list')
      } catch (error) {
        if (error.response.status === 401) {
          this.logout()
        }
      }
    },
    ...mapActions([
      'fetchAuthenticatedUser',
      'refreshApi',
      'fetchPortalList',
      'logout',
    ]),
    ...mapMutations(['setQuery']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        /**
         * How to update page title, we couldn't update the router directly because ionic work differently that
         * normal vue app
         * @see https://stackoverflow.com/questions/51639850/how-to-change-page-titles-when-using-vue-router
         */
        refreshDocumentTitle(to, this.$t)

        if (this.$store.getters.currentUser) {
          try {
            this.fetchSelectedProject()
          } catch (error) {
            console.error('fetchSelectedProject', error)
          }
        }
      },
    },
    '$i18n.locale': {
      immediate: true,
      handler(locale) {
        refreshDocumentTitle(this.$route, this.$t)
        document.documentElement.setAttribute('lang', locale)
      },
    },
    async idTokenPayload(idTokenPayload) {
      if (idTokenPayload?.email) {
        await this.initializeWhenUserAuthenticated()
      }
    },
  },
})
</script>

const indexPortalsById = (byId, portal) => ({
  ...byId,
  [portal.id]: portal,
})

export const setPortals = (state, portals) => {
  state.byId = portals.reduce(indexPortalsById, {})
  state.listOfIds = portals.map(p => p.id)
  state.areFetched = true
}

export const setSomePortals = (state, portals) => {
  state.byId = portals.reduce(indexPortalsById, state.byId)
  state.listOfIds = [
    ...new Set([...state.listOfIds, ...portals.map(p => p.id)]),
  ]
}

import {
  convertToJs,
  convertRequestAccessToJs,
} from '@/utils/modelConvertors/project.converter'
import * as user from '@/utils/modelConvertors/user.converter'
import * as permissions from '@/utils/modelConvertors/permissions.project.converter.js'
import { convertJsNameToRimnat } from '@/utils/modelConvertors/permissions.project.converter.js'
import { safeArray } from '@/utils/array'
import { isGeoreferenced } from '@/utils/project'
import { formDataHeaders } from '@/utils/form-data'

export async function fetchProjectsOfPortal(
  { rootGetters, commit },
  { portalId }
) {
  const options = {
    params: {
      user_id: rootGetters.currentUser?.id,
      portal_id: portalId,
    },
  }

  const portal = this.getters.getPortalById(portalId)

  let url = '/user/getUserAuthorizedPortalProjects'

  if (portal.permissions.manage || portal.permissions.projectsManage) {
    url = '/portal/getPortalProjectsInfos'
  } else if (portal.permissions.projectsSee) {
    url = '/portal/getPortalProjectsInfosWithUserAccessibility'
  } else if (options.params.user_id != null) {
    url = '/user/getUserAuthorizedPortalProjects'
  } else {
    // public gallery
    //TO DO
    //url = '/user/getUserAuthorizedPortalProjects'
  }

  try {
    const { data } = await this.$api.get(url, options)
    const projects = safeArray(data)
      .map(convertToJs)
      //override portalId for each project (data was not getted from api request)
      .map(p => ({ ...p, portalId }))

    commit('setProjects', { portalId, projects })
  } catch (error) {
    console.error('error', error)
  }
}

export async function fetchCoordinateSystem(_, project) {
  const url = '/coordinate/getCoordinatesSystemFromId'
  const params = {
    value: project.coordinateSystem.id,
  }
  const { data } = await this.$api.get(url, { params })
  return Array.isArray(data) && data.length === 1 ? data[0] : null
}

export async function fetchProjectWithoutDetails(_, { projectId }) {
  const options = {
    params: {
      project_id: projectId,
    },
  }

  const url = '/project/getProjectInfos'

  const { data } = await this.$api.get(url, options)

  return data
}

export async function fetchProjectCapabilities(_, { projectId, userId }) {
  const options = {
    params: {
      project_id: projectId,
      user_id: userId,
    },
  }

  const url = '/user/getUserProjectCaps'

  const { data } = await this.$api.get(url, options)

  console.log('fetchProjectCapabilities', data)

  return safeArray(data)[0]
}

export async function fetchProject(
  { commit, dispatch, rootGetters },
  projectId
) {
  console.log('fetchProject')
  const userId = rootGetters.currentUser?.id

  try {
    let data = {
      ...(await dispatch('fetchProjectWithoutDetails', { projectId })),
      // getProjectInfos doesn't return all the required infos like the portal_id so we have to use another request to get the rest
      ...(await dispatch('fetchProjectCapabilities', { projectId, userId })),
    }
    const project = convertToJs(data)

    if (isGeoreferenced(project)) {
      const coordinateSystem = await dispatch('fetchCoordinateSystem', project)
      if (coordinateSystem) {
        project.coordinateSystem = coordinateSystem
      }
    }

    if (!project.portalId) {
      // we absolutely need to have the portalId
      const [portal] = await dispatch('fetchPortalsWithProject', project.id)
      project.portalId = portal.id
      console.log('fetchProject', portal)
    }

    commit('addProject', { project })
  } catch (error) {
    console.error('error', error)
  }
}

export async function fetchProjectUsers({ commit }, { projectId }) {
  const options = {
    params: {
      project_id: projectId,
    },
  }

  const url = '/project/getProjectUsers'

  const { data } = await this.$api.get(url, options)

  if (!data) {
    return
  }

  const users = data.map(user.convertToJs)
  const userPermissions = data
    .map(permissions.convertToJs)
    .reduce((previous, current) => {
      previous[current.userId] = current
      return previous
    }, {})

  commit('setUsers', users)
  commit('setProjectMembers', {
    projectId,
    userIds: users.map(u => u.id),
    userPermissions,
  })
}

export async function changeProjectUserPermissions(
  { commit },
  { portalId, projectId, userId, name, value }
) {
  const payload = new FormData()
  payload.append('portal_id', portalId)
  payload.append('project_id', projectId)
  payload.append('user_id', userId)
  payload.append('parameter', convertJsNameToRimnat(name))
  payload.append('value', value ? 1 : 0)
  const response = await this.$api.post('/project/setProjectUserCap', payload)
  if (response?.data === 'ok')
    commit('changeUserPermissionForProject', { projectId, userId, name, value })
}

export async function sendRequestAccess({ rootGetters, commit }, { project }) {
  const form = new FormData()
  form.append('portal_id', parseInt(project.portalId, 10))
  form.append('project_id', parseInt(project.id, 10))
  form.append('user_id', parseInt(rootGetters.currentUser?.id, 10))

  const url = '/project/createProjectAccessRequest'

  const formOptions = {
    ...formDataHeaders,
    timeout: 60000,
  }
  const { data } = await this.$api.post(url, form, formOptions)

  //const data = { accepted:'0', closed:'0', sent: '1', user_accessible: '0', hash }

  const access = convertRequestAccessToJs(data)

  commit('updateRequestAccess', { project, access })
}

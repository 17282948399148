import { getFullReport } from '@/utils/diagnostic/report'
import { formDataHeaders } from '@/utils/form-data'

export async function runDiagnostic({ rootGetters, commit }, context) {
  const report = await getFullReport(context)
  const form = new FormData()
  form.append('user_id', parseInt(rootGetters.currentUser?.id, 10))
  form.append('data', JSON.stringify(report))

  const url = '/user/logUserDiagnostic'
  const { data } = await this.$api.post(url, form, formDataHeaders)

  if (!data) {
    throw new Error('no result')
  }
  if (data.status === 'error') {
    throw data
  }

  commit('setDiagnosticRunOnThisComputer')
}

export function startDiagnosticIfFirstTime({ commit, getters }) {
  if (!getters.hasBeenRunOnThisComputer) {
    commit('startDiagnostic', { silent: true })
  }
}

export function startDiagnosticManually({ commit }) {
  commit('startDiagnostic', { silent: false })
}

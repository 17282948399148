import { safeArray } from '@/utils/array'
import { values } from 'lodash'

export const setForegroundFetchIsBusy = (state, value) => (state.busy = value)

export const setForegroundFetchesInCache = (state, { fetches }) => {
  const inCache = {}
  for (const f of safeArray(fetches)) {
    if (typeof f.fetchId === 'string') inCache[f.fetchId] = f
  }
  state.inCache = inCache
}

export const discardForegroundFetches = (state, { ids = null }) => {
  if (ids === null) {
    state.byId = {}
    state.inCache = {}
  } else {
    for (const id of safeArray(ids)) {
      delete state.byId[id]
      delete state.inCache[id]
    }
  }
}

export const startForegroundFetch = (state, { id, total = 0 }) => {
  const fgFetch = {
    id,
    result: '',
    inProgress: true,
    total,
    downloaded: 0,
    failed: 0,
    abortRequested: false,
  }
  state.byId[id] = fgFetch
  return fgFetch
}

export const requestAbortForegroundFetches = (state, { ids = null } = {}) => {
  const fetches =
    ids == null
      ? values(state.byId)
      : safeArray(ids)
          .map(id => state.byId[id])
          .filter(Boolean)
  fetches.forEach(f => (f.abortRequested = true))
}

export const updateForegroundFetch = (
  state,
  { id, downloaded, failed, result = '' }
) => {
  const fgFetch = state.byId[id]
  if (!fgFetch) return
  if (downloaded != null) fgFetch.downloaded += downloaded
  if (failed != null) fgFetch.failed += failed
  if (result !== '') {
    fgFetch.result = result
    fgFetch.inProgress = false
  }
}

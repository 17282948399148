import * as mutations from './personal-note.mutations'
import * as getters from './personal-note.getters'

import { addReset } from '../utils'

const state = {
  byProjectId: {},
}

export default addReset({
  state,
  actions: {},
  mutations,
  getters,
})

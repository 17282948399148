export const addUser = (state, user) => {
  state.byId[user?.id] = user
  state.listOfIds.push(user?.id)
  state.listOfIds = [...new Set(state.listOfIds)]
}

export const setUsers = (state, users) => {
  users.forEach(user => {
    addUser(state, user)
  })
}

// const isUserExist = (state, user) => (state.byId[getUserId(id)] = user)

export const setCurrentUser = (state, user) => {
  state.currentUserId = user?.id
}

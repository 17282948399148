import {
  getCachedFetches,
  getCacheNameForFetchId,
} from './fetch-foreground.utils'
import { safeArray } from '@/utils/array'

function didRequestAbortOfForegroundFetch(state, id) {
  return state.byId[id]?.abortRequested || false
}

export async function examineCacheForForegroundFetches({ commit, state }) {
  if (state.busy) {
    console.error(`invalid state for 'examineCacheForForegroundFetches'`)
    return
  }
  commit('setForegroundFetchIsBusy', true)
  const fetches = await getCachedFetches()
  commit('setForegroundFetchesInCache', { fetches })
  commit('setForegroundFetchIsBusy', false)
}

export async function clearForegroundFetches(
  { commit, dispatch, state },
  { ids = null } = {}
) {
  if (state.busy) {
    console.error(`invalid state for 'examineCacheForForegroundFetches'`)
    return
  }
  commit('setForegroundFetchIsBusy', true)
  dispatch('abortForegroundFetches', { ids })
  const cachedFetches = await getCachedFetches()
  const fetchIdsToRemove = ids == null ? null : new Set(safeArray(ids))
  await Promise.allSettled(
    cachedFetches.map(async f => {
      if (fetchIdsToRemove == null || fetchIdsToRemove.has(f.fetchId)) {
        console.log(`removing cache ${f.cacheName} (fetch id ${f.fetchId})`)
        await window.caches.delete(f.cacheName)
      }
    })
  )
  commit('discardForegroundFetches', { ids })
  commit('setForegroundFetchIsBusy', false)
}

async function beginForegroundFetch(
  { state, commit },
  { id, notify, batchSize = 32, mode = '' },
  requestGetter
) {
  if (state.byId[id]?.inProgress) {
    console.error(`Foreground fetch id ${id} already in progress`)
    return
  }
  commit('startForegroundFetch', { id })
  const notifyFunction = typeof notify === 'function' ? notify : null
  const update = async changes => {
    commit('updateForegroundFetch', changes)
    if (notifyFunction) {
      await notifyFunction(state.byId[id])
    }
  }
  await update({ id })
  try {
    const requests = await requestGetter.call()
    if (Array.isArray(requests) && requests.length > 0) {
      commit('startForegroundFetch', { id, total: requests.length })
      await update({ id })
      const cacheName = getCacheNameForFetchId(id, requests.length)
      const cache = await window.caches.open(cacheName)
      const init = mode ? { mode } : undefined
      for (let i = 0; ; i += batchSize) {
        if (didRequestAbortOfForegroundFetch(state, id)) {
          await update({ id, result: 'cancelled' })
          return
        }
        const batch = requests.slice(i, i + batchSize)
        if (batch.length === 0) break
        const results = await Promise.allSettled(
          batch.map(async url => {
            try {
              const response = await fetch(url, init)
              if (didRequestAbortOfForegroundFetch(state, id)) return false
              await cache.put(url, response)
              return true
            } catch (error) {
              return false
            }
          })
        )
        const successCount = results.filter(
          r => r.status === 'fulfilled' && r.value
        ).length
        await update({
          id,
          downloaded: successCount,
          failed: results.length - successCount,
        })
      }
    }
    await update({ id, result: 'done' })
  } catch (error) {
    await update({ id, result: error?.message || 'unknown error' })
  }
}

export async function beginForegroundFetchForRequests(
  context,
  { id, requests = [], notify, batchSize = 32, mode = '' }
) {
  if (requests)
    beginForegroundFetch(
      context,
      { id, batchSize, notify, mode },
      async () => requests
    )
}

export async function beginForegroundFetchForUrlList(
  context,
  { id, url: baseUrl = null, notify, batchSize = 32 }
) {
  if (!baseUrl) return
  beginForegroundFetch(
    context,
    { id, baseUrl, batchSize, notify },
    async () => {
      const response = await fetch(baseUrl)
      if (response.ok) {
        const content = await response.text()
        const requests = content
          .split(/\r?\n/)
          .filter(l => l.length > 0)
          .map(l => new URL(l, baseUrl))
        console.log(`will fetch ${requests.length} URLs as ${id}`)
        return requests
      } else {
        const msg = `failed to retrieve URLs | ${response.status}`
        console.error(msg + ' | ' + baseUrl)
        throw new Error(msg)
      }
    }
  )
}

export async function abortForegroundFetches({ commit }, { ids = null } = {}) {
  commit('requestAbortForegroundFetches', { ids })
}

import { App } from '@capacitor/app'

import { isRunningWithCapacitor } from '@/utils/device'
import config from '@/utils/config'

const removeLastCharacter = string => string.substring(0, -1)

/**
 * Auth0 with Ionic/vue doesn't have any tutorials. These are the tutorials used for this implementation.
 * - [Auth0 with cordova](https://auth0.com/docs/quickstart/native/cordova/01-login)
 * - [Auth0 with capacitor/angular](https://auth0.com/docs/quickstart/native/ionic-angular/01-login)
 * - [How to add add a capacitor listener in vue.js](https://forum.ionicframework.com/t/reference-vue-store-from-capacitor-listener/209824/2)
 * - [Deeplinks in capacitor with vue.js](https://capacitorjs.com/docs/guides/deep-links#vue)
 *
 * We simplified the deep link.
 */
const deepLinkPrefix = async () => {
  if (isRunningWithCapacitor()) {
    const { id } = await App.getInfo()

    return `${id}://${config.domain}/capacitor/${id}`
  }

  const host = window.location.origin
  const baseURL = removeLastCharacter(process.env.BASE_URL) // last character is a `/`
  return `${host}${baseURL}`
}

export const buildDeepLink = async path => {
  const cleanPath = path.replace(/\/:[a-zA-Z\\?]*/, '')

  const prefix = await deepLinkPrefix()

  return `${prefix}${cleanPath}`
}

const hashHashIdToken = uri => new URL(uri).hash.startsWith('#id_token')

export const isAuth0Link = uri =>
  (uri.includes('code=') && uri.includes('state=')) || hashHashIdToken(uri)

export const getProjectCoverUrl = project => {
  const id = typeof project === 'string' ? project : project?.id
  return `${config.imageServerUrl}/project/cover/${id}/thumb_500_500.jpg`
}

export const getProjectCoverUrlError = () =>
  `${config.imageServerUrl}/project/cover/undefined/thumb_1000_1000.jpg` // 500_500 doesn't exist

export const getProjectLargeCoverUrl = project => {
  const id = typeof project === 'string' ? project : project?.id
  return `${config.imageServerUrl}/project/cover/${id}/thumb_1000_1000.jpg`
}

export const getProjectLargeCoverUrlError = () =>
  `${config.imageServerUrl}/project/cover/undefined/thumb_1000_1000.jpg`

export const getUserAvatarUrl = user => {
  const id = typeof user === 'string' ? user : user?.id
  return `${config.imageServerUrl}/user/avatar/${id}/thumb_150_150.jpg`
}

export const getMediaUrl = mediaId =>
  `${config.imageServerUrl}/${mediaId}/picture.jpg`

/**
 * @see https://developers.google.com/maps/documentation/urls/get-started
 */
export const getGoogleMapLink = project =>
  `https://www.google.com/maps/search/?api=1&query=${project.latitude},${project.longitude}`

export const isDeepLink = async url => {
  const prefix = await deepLinkPrefix()

  return url.startsWith(prefix)
}

export const extractPathFromDeepLink = async url => {
  const prefix = await deepLinkPrefix()

  return url.replace(prefix, '')
}

export const isValidURL = url => {
  // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url

  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(url)
}

const containsAtLeastOneLetterOrNumber = str => str.match(/[a-z0-9]+/)

export const prettyName = project => {
  let name =
    project?.name?.replace(new RegExp(`^${project.slug}\\s/ `), '') || ''

  if (!containsAtLeastOneLetterOrNumber(name)) {
    name = project?.name || ''
  }

  return name.trim()
}

const includesWord = (property, word) => property.toLowerCase().includes(word)

const onlyIncludingQuery = query => project =>
  query
    .toLowerCase()
    .split(' ')
    .reduce(
      (acc, word) =>
        acc &&
        (includesWord(project.name, word) ||
          includesWord(project.slug, word) ||
          project.keywords.some(k => includesWord(k, word))),
      true
    )

export const search = (projects, query) => {
  if (!query) {
    return projects
  }

  return projects.filter(onlyIncludingQuery(query))
}

export const isGeoreferenced = project =>
  (project?.coordinateSystem?.id && // until the coordinateSystem is fetched we should based on the id
    project?.coordinateSystem?.id !== '0') || // due to HTTP API bug
  project?.coordinateSystem?.proj4

export const hasAccessToProject = (project, portal) =>
  Boolean(project?.permissions?.access) ||
  Boolean(portal?.permissions?.projectsManage) ||
  project?.access?.status === ProjectAccess.ACCESSIBLE

export const ProjectAccess = Object.freeze({
  ACCESSIBLE: 'ACCESSIBLE',
  NOT_ACCESSIBLE: 'NOT_ACCESSIBLE',
  ACCEPTED: 'ACCEPTED',
  IN_PROGRESS: 'IN_PROGRESS',
  REJECTED: 'REJECTED',
})

import { addReset } from '../utils'

const state = {
  stats: {},
}

export default addReset({
  state,
  actions: {},
  mutations: {
    registerAction: (state, actionId) => {
      if (!state.stats[actionId]) {
        state.stats[actionId] = 0
      }

      state.stats[actionId] += 1
    },
  },
  getters: {
    getActionStat: state => actionId => state.stats[actionId] || 0,
  },
})

import * as actions from './user.actions'
import * as mutations from './user.mutations'
import * as getters from './user.getters'

import { addReset } from '../utils'

const state = {
  byId: {},
  listOfIds: [],
  currentUserId: null,
  language: null,
}

export default addReset({
  state,
  actions,
  mutations,
  getters,
})

export * from '../../utils/modelConvertors/user.converter'

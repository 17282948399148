import * as actions from './project.actions'
import * as mutations from './project.mutations'
import * as getters from './project.getters'

import { addReset } from '../utils'

const state = {
  byId: {},
  byPortalId: {},
}

export default addReset({
  state,
  actions,
  mutations,
  getters,
})

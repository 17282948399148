export const setLanguage = (state, language) => {
  state.language = language
}

export const setQuery = (state, query) => {
  state.local.query = query
}

export const setProjectsLayout = (state, layout) => {
  state.local.projects.layout = layout
}

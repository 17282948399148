import { difference, keys, values } from 'lodash'
import { ConnectionSpeed } from '@/store/offline/offline.utils.js'

function newSync(name, delay, fn) {
  if (typeof fn !== 'function') throw new TypeError('fn must be a function')
  const result = {
    name,
    timeoutID: 0,
    delay: delay || 30000,
    count: 0,
    previous: {},
    callback: cb,
  }
  function cb() {
    try {
      console.log(`calling ${result.name} (#${result.count})`)
      Promise.resolve(fn(result.count, result.previous))
        .then(result => (result.previous = Object.freeze({ result })))
        .catch(error => (result.previous = Object.freeze({ error })))
    } catch (error) {
      result.previous = Object.freeze({ error })
    }
    result.count += 1
  }
  return result
}

function startAttempt(sync) {
  if (!sync) return
  function next() {
    sync.timeoutID = setTimeout(() => {
      sync.callback()
      if (sync.timeoutID > 0) next()
    }, sync.delay)
  }
  if (sync.timeoutID > 0) {
    console.warn(`sync ${name} already running`)
  } else {
    next()
  }
  return sync
}

function stopAttempt(sync) {
  if (!sync || sync.timeoutID === 0) return
  clearTimeout(sync.timeoutID)
  sync.timeoutID = 0
}

function updateSyncMap(map, finalIds, startSync, factory) {
  console.assert(typeof factory === 'function')
  const current = keys(map)
  difference(current, finalIds).forEach(id => {
    stopAttempt(map[id])
    delete map[id]
  })
  difference(finalIds, current).forEach(id => {
    const sync = factory(id)
    if (sync) map[id] = sync
  })
  if (startSync) startSyncMap(map)
  return map
}

function startSyncMap(map) {
  values(map)
    .filter(s => s.timeoutID === 0)
    .forEach(startAttempt)
  return map
}

function stopSyncMap(map) {
  values(map)
    .filter(s => s.timeoutID > 0)
    .forEach(startAttempt)
  return map
}

export const offlineSyncPlugin = store => {
  const sync = {
    resumeSyncTimeoutID: 0,
    isActive: false,
    pendingWorkStates: {},
    pendingMedias: {},
    pendingAnnotations: {},
  }
  // watch connectivity status
  const delaySyncResume = 5
  const maps = [
    sync.pendingWorkStates,
    sync.pendingMedias,
    sync.pendingAnnotations,
  ]
  const networkStatus = store.watch(
    (_, getters) => getters.networkInfo.actualSpeed,
    speed => {
      if (speed === ConnectionSpeed.none) {
        sync.isActive = false
        if (sync.resumeSyncTimeoutID > 0) {
          console.log(`network connection lost before ${delaySyncResume}s`)
          clearTimeout(sync.resumeSyncTimeoutID)
          sync.resumeSyncTimeoutID = 0
        } else {
          console.log(`network connection lost, stopping sync workers`)
        }
        maps.forEach(stopSyncMap)
      } else if (sync.resumeSyncTimeoutID === 0) {
        console.log(`network connection speed is now: ${speed}`)
        sync.resumeSyncTimeoutID = setTimeout(() => {
          console.log(`resuming sync workers`)
          sync.isActive = true
          maps.forEach(startSyncMap)
          sync.resumeSyncTimeoutID = 0
        }, delaySyncResume * 1000)
      }
    }
  )
  // watch pending work states
  const pendingWorkStates = store.watch(
    (_, getters) => getters.projectIdsWithPendingWorkStates,
    projectIds =>
      updateSyncMap(
        sync.pendingWorkStates,
        projectIds,
        sync.isActive,
        projectId =>
          newSync(`pending work-states of project ${projectId}`, 5000, () =>
            store.dispatch('savePendingWorkStates', { projectId })
          )
      )
  )
  // watch pending media
  const pendingMedias = store.watch(
    state => state.media.pendingMediaIds,
    mediaIds =>
      updateSyncMap(sync.pendingMedias, mediaIds, sync.isActive, mediaId =>
        newSync(`pending media ${mediaId}`, 3000, () =>
          store.dispatch('savePendingMedia', { mediaId })
        )
      ),
    { deep: true }
  )
  // watch pending annotations
  const pendingAnnotations = store.watch(
    state => state.annotations.pendingAnnotationIds,
    annotationIds =>
      updateSyncMap(
        sync.pendingAnnotations,
        annotationIds,
        sync.isActive,
        annotationId =>
          newSync(`pending annotation ${annotationId}`, 10000, () =>
            store.dispatch('savePendingAnnotation', { annotationId })
          )
      ),
    { deep: true }
  )
  // todo - watch offline projects
  return () => {
    if (typeof networkStatus === 'function') networkStatus()
    if (typeof pendingWorkStates === 'function') pendingWorkStates()
    if (typeof pendingMedias === 'function') pendingMedias()
    if (typeof pendingAnnotations === 'function') pendingAnnotations()
  }
}

import { api } from '@/plugins/api'

export const cleanGpuModel = gpuModel =>
  (gpuModel || '')
    .toLowerCase()
    .replace('(r)', '')
    .replace('(c)', '')
    .replace('(ivb gt2)', '')
    .replace('kbl', '')
    .replace('gt2', '')
    .replace('corporation', '')
    .replace('corp', '')
    .replace('sse2', '')
    .replace('pcie', '')
    .replace('super', '')
    .replace('.', '')
    .replace(/,/g, '')
    .replace('/', '')
    .replace(/\s\s/g, ' ')
    .trim()

export const getGpuFullName = device => cleanGpuModel(device?.gpu?.model)

export const getGpuLongName = gpuFullName =>
  (gpuFullName.match(/\(([^)]+)\)/)?.[1] || gpuFullName).trim()

// TODO: refactor to show the feature
export const getGpuShortName = gpuLongName => {
  let value = gpuLongName
  if (value.includes('direct3d')) {
    value = value.replace('direct', ';').split(';')[0].trim()
  }
  if (value.includes('open')) {
    value = value.replace('open', ';').split(';')[0].trim()
  }
  return value
}

export const getGpuModel = gpuShortName =>
  gpuShortName.replace('nvidia ', '').replace('amd ', '').trim()

export const getGpuIsNvidiaOrAmd = gpuFullName =>
  gpuFullName.includes('nvidia') || gpuFullName.includes('amd')

export const getGpuIsIntel = gpuFullName => gpuFullName.includes('intel')

export const getGpuBenchmark = async gpuModel => {
  const params = { gpu_name: gpuModel }
  const url = '/gpu/getGpuBenchmarkV2'
  const { data } = await api().get(url, { params })

  return data
}

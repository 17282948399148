export const getParameter = (context, parameterName) =>
  context.getParameter(context[parameterName])

export const getExtensionParameter = (
  context,
  extensionName,
  parameterName
) => {
  const extension = context.getExtension(extensionName)

  return extension ? context.getParameter(extension[parameterName]) : null
}

const constraints = {
  antialias: true,
  alpha: true,
  depth: true,
}

export const getContext = renderer =>
  renderer.renderer.getContext('webgl2', constraints)

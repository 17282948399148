import { addReset } from '../utils.js'
import * as mutations from './fetch-foreground.mutations'
import * as actions from './fetch-foreground.actions'
import * as getters from './fetch-foreground.getters'

const state = () => ({
  busy: false,
  byId: {},
  inCache: {},
})

export default addReset({
  state,
  mutations,
  actions,
  getters,
})

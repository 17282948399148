import { uniq } from 'lodash'
import { safeArray } from '@/utils/array.js'
import { convertToHttp } from '@/utils/modelConvertors/annotation.converter.js'

const initializeProjectIfNecessary = (state, projectId) => {
  if (!state.listOfIdsByProjectId[projectId]) {
    state.listOfIdsByProjectId[projectId] = []
  }
}

export const setColors = (state, colors) => {
  state.colors = colors
}

export const addAnnotation = (state, { annotation, pending }) => {
  initializeProjectIfNecessary(state, annotation.projectId)
  state.byId[annotation.id] = annotation
  state.listOfIdsByProjectId[annotation.projectId].push(annotation.id)
  state.listOfIdsByProjectId[annotation.projectId] = uniq(
    state.listOfIdsByProjectId[annotation.projectId]
  )
  if (pending) state.pendingAnnotationIds.push(annotation.id)
}

export const setAnnotationServerId = (state, { localId, serverId }) => {
  const annotation = state.byId[localId]
  if (annotation == null) return
  const projectId = annotation.projectId
  delete state.byId[localId]
  annotation.id = serverId
  annotation.raw = convertToHttp(annotation)
  state.byId[serverId] = annotation
  let idx = state.pendingAnnotationIds.indexOf(localId)
  if (idx >= 0) state.pendingAnnotationIds.splice(idx, 1)
  idx = safeArray(state.listOfIdsByProjectId[projectId]).indexOf(localId)
  if (idx >= 0) state.listOfIdsByProjectId[projectId][idx] = serverId
  idx = safeArray(state.displayedAnnotationByProjectId[projectId]).indexOf(
    localId
  )
  if (idx >= 0) state.displayedAnnotationByProjectId[projectId][idx] = serverId
  idx = safeArray(state.areDeleting).indexOf(localId)
  if (idx >= 0) state.areDeleting[idx] = serverId
}

export const changeAnnotation = (state, { annotation }) => {
  state.byId[annotation.id] = { ...state.byId[annotation.id], ...annotation }
}

export const setAnnotations = (state, { annotations, projectId }) => {
  safeArray(state.listOfIdsByProjectId[projectId]).forEach(annotationId => {
    delete state.byId[annotationId]
  })
  annotations.forEach(annotation => {
    state.byId[annotation.id] = annotation
  })
  state.listOfIdsByProjectId[projectId] = annotations.map(a => a.id)
}

const initializeDisplayedAnnotationIfNecessary = (state, projectId) => {
  if (!state.displayedAnnotationByProjectId[projectId]) {
    state.displayedAnnotationByProjectId[projectId] = {
      displayedAnnotationId: null,
    }
  }
}

export const setDisplayedAnnotation = (state, { annotationId, projectId }) => {
  initializeDisplayedAnnotationIfNecessary(state, projectId)
  state.displayedAnnotationByProjectId[projectId].displayedAnnotationId =
    annotationId
}

export const prepareDeleteAnnotation = (state, { annotationId }) => {
  state.areDeleting.push(annotationId)
}

export const cancelDeleteAnnotation = (state, { annotationId }) => {
  state.areDeleting = state.areDeleting.filter(id => id !== annotationId)
}

export const deleteAnnotation = (state, { annotationId, projectId }) => {
  state.areDeleting = state.areDeleting.filter(id => id !== annotationId)
  delete state.byId[annotationId]
  state.listOfIdsByProjectId[projectId] = state.listOfIdsByProjectId[
    projectId
  ].filter(id => id !== annotationId)

  if (
    state.displayedAnnotationByProjectId[projectId]?.displayedAnnotationId ===
    annotationId
  ) {
    state.displayedAnnotationByProjectId[projectId].displayedAnnotationId = null
  }
}

import { convertToJs } from '@/utils/modelConvertors/portal.converter'
import { convertToJs as toPermissions } from '@/utils/modelConvertors/permissions.portal.converter'

import { safeArray } from '@/utils/array'

async function fetchPortalCaps(api, userId, portalId) {
  if (!userId || !portalId) {
    return null
  }
  const url = '/user/getUserPortalCaps'
  const params = {
    user_id: userId,
    portal_id: portalId,
  }
  const { data } = await api.get(url, { params })
  return typeof data === 'object' ? data : null
}

async function fetchAndAddPortalCaps(api, userId, portals) {
  if (!userId || portals == null) return []
  const caps = await Promise.allSettled(
    portals.map(p => fetchPortalCaps(api, userId, p.id))
  )
  portals.forEach((p, idx) => {
    const status = idx < caps.length ? caps[idx].status : ''
    if (status === 'rejected') {
      console.error(caps[idx].reason)
      p.permissions = ''
    } else if (status === 'fulfilled') {
      p.permissions = toPermissions(caps[idx].value)
    }
  })
  return portals
}

export async function fetchPortalList({ rootGetters, commit }) {
  const { currentUser } = rootGetters

  const params = {
    user_id: currentUser?.id,
  }

  try {
    const { data } = await this.$api.get('/user/getUserAuthorizedPortals', {
      params,
    })

    const portals = safeArray(data).map(convertToJs)

    const portalsWithCapabilities = await fetchAndAddPortalCaps(
      this.$api,
      currentUser?.id,
      portals
    )

    commit('setPortals', portalsWithCapabilities)
  } catch (error) {
    console.error('error', error)
  }
}

export async function fetchPortalsWithProject({ rootGetters }, projectId) {
  const params = {
    project_id: projectId,
  }

  try {
    const { data } = await this.$api.get('/public/getProjectPortals', {
      params,
    })

    const portals = safeArray(data).map(convertToJs)

    const { currentUser } = rootGetters
    const portalWithCapabilities = await fetchAndAddPortalCaps(
      this.$api,
      currentUser?.id,
      portals
    )

    // commit('setSomePortals', portalWithCapabilities)

    return portalWithCapabilities
  } catch (error) {
    console.error('error', error)
  }
}

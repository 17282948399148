import { ViewerObjectTypes } from './viewer.utils'

export const setViewerStatus = (state, status) => {
  state.status = status
}

export const setViewerState = (state, { projectId, viewerState }) => {
  state.byProjectId[projectId] = {
    ...state.byProjectId[projectId],
    ...viewerState,
  }
}

const addViewerBinding = (state, { projectId, id, viewerId, typeOfData }) => {
  if (!state.byProjectId[projectId]) {
    state.byProjectId[projectId] = {
      [typeOfData]: {
        [id]: {
          viewerId,
          visible: true,
        },
      },
    }
  } else if (!state.byProjectId[projectId][typeOfData]) {
    state.byProjectId[projectId][typeOfData] = {
      [id]: {
        viewerId,
        visible: true,
      },
    }
  } else if (!state.byProjectId[projectId][typeOfData][id]) {
    state.byProjectId[projectId][typeOfData][id] = {
      viewerId,
      visible: true,
    }
  } else {
    state.byProjectId[projectId][typeOfData][id].viewerId = viewerId
  }
}

export const addViewerAnnotationBinding = (
  state,
  { projectId, id, viewerId }
) => {
  addViewerBinding(state, {
    projectId,
    id,
    viewerId,
    typeOfData: ViewerObjectTypes.ANNOTATIONS,
  })
}

export const addViewerLayerBinding = (state, { projectId, id, viewerId }) => {
  addViewerBinding(state, {
    projectId,
    id,
    viewerId,
    typeOfData: ViewerObjectTypes.LAYERS,
  })
}

const setVisibility = (
  state,
  { projectId, id, viewerObjectType, visibility }
) => {
  state.byProjectId[projectId][viewerObjectType][id].visible = visibility
}

export const setLayerVisibility = (state, { projectId, id, visibility }) => {
  setVisibility(state, {
    projectId,
    id,
    visibility,
    viewerObjectType: ViewerObjectTypes.LAYERS,
  })
}

export const setAnnotationVisibility = (
  state,
  { projectId, id, visibility }
) => {
  setVisibility(state, {
    projectId,
    id,
    visibility,
    viewerObjectType: ViewerObjectTypes.ANNOTATIONS,
  })
}

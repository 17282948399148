import { mapValues } from 'lodash'
import getAngle from './angle'
import { getParameter, getExtensionParameter } from './context'
import describeRange from './describeRange'
import webGLFunctionNames from './webGLFunctionNames'

const webGlReportConversion = {
  glVersion: 'VERSION',
  shadingLanguageVersion: 'SHADING_LANGUAGE_VERSION',
  vendor: 'VENDOR',
  renderer: 'RENDERER',
  redBits: 'RED_BITS',
  greenBits: 'GREEN_BITS',
  blueBits: 'BLUE_BITS',
  alphaBits: 'ALPHA_BITS',
  depthBits: 'DEPTH_BITS',
  stencilBits: 'STENCIL_BITS',
  maxRenderBufferSize: 'MAX_RENDERBUFFER_SIZE',
  maxCombinedTextureImageUnits: 'MAX_COMBINED_TEXTURE_IMAGE_UNITS',
  maxCubeMapTextureSize: 'MAX_CUBE_MAP_TEXTURE_SIZE',
  maxFragmentUniformVectors: 'MAX_FRAGMENT_UNIFORM_VECTORS',
  maxTextureImageUnits: 'MAX_TEXTURE_IMAGE_UNITS',
  maxTextureSize: 'MAX_TEXTURE_SIZE',
  maxVaryingVectors: 'MAX_VARYING_VECTORS',
  maxVertexAttributes: 'MAX_VERTEX_ATTRIBS',
  maxVertexTextureImageUnits: 'MAX_VERTEX_TEXTURE_IMAGE_UNITS',
  maxVertexUniformVectors: 'MAX_VERTEX_UNIFORM_VECTORS',
}

const webGlReportConversionWithRange = {
  aliasedLineWidthRange: 'ALIASED_LINE_WIDTH_RANGE',
  aliasedPointSizeRange: 'ALIASED_POINT_SIZE_RANGE',
  maxViewportDimensions: 'MAX_VIEWPORT_DIMS',
}

const dbgRenderInfoConversion = {
  unMaskedRenderer: 'UNMASKED_RENDERER_WEBGL',
  unMaskedVendor: 'UNMASKED_VENDOR_WEBGL',
}

const getUnmaskedRendererInfo = getExtensionParam =>
  mapValues(
    dbgRenderInfoConversion,
    paramName => getExtensionParam('WEBGL_debug_renderer_info', paramName) || ''
  )

const getMaxAnisotropy = context => {
  const extension =
    context.getExtension('EXT_texture_filter_anisotropic') ||
    context.getExtension('WEBKIT_EXT_texture_filter_anisotropic') ||
    context.getExtension('MOZ_EXT_texture_filter_anisotropic')

  // See Canary bug: https://code.google.com/p/chromium/issues/detail?id=117450
  return extension
    ? context.getParameter(extension.MAX_TEXTURE_MAX_ANISOTROPY_EXT) || 2
    : 'n/a'
}

// TODO: the second parameter is weird for me but I don't change from v1 since I don't understand everything
const getWebGL2Status = (context, contextName = 'webgl2') => {
  const isWebGL2 = contextName.includes('webgl2')

  if (!isWebGL2) {
    return {
      webgl2Status: 'webgl2 and experimental-webgl2 contexts not available.', // based on the current implementation it will never happen
      webgl2Functions: [],
    }
  }

  const isImplemented = functionName => context[functionName]

  const webgl2Functions = webGLFunctionNames.map(functionName => ({
    name: functionName,
    className: `extension${isImplemented(functionName) ? '' : ' unsupported'}`,
  }))

  const totalImplemented = webGLFunctionNames.filter(isImplemented)
  const length = webGLFunctionNames.length

  return {
    webgl2Status: `${totalImplemented} of ${length} new functions implemented.`,
    webgl2Functions,
  }
}

export const getWebGlReport = context => {
  const getParam = paramName => getParameter(context, paramName)
  const getExtensionParam = (extensionName, paramName) =>
    getExtensionParameter(context, extensionName, paramName)

  return {
    ...mapValues(webGlReportConversion, getParam),
    ...mapValues(webGlReportConversionWithRange, paramName =>
      describeRange(getParam(paramName))
    ),
    ...getUnmaskedRendererInfo(getExtensionParam),
    antialias: context.getContextAttributes().antialias
      ? 'Available'
      : 'Not available',
    angle: getAngle(context),
    maxColorBuffers:
      getExtensionParam('WEBGL_draw_buffers', 'MAX_DRAW_BUFFERS_WEBGL') || 1,
    maxAnisotropy: getMaxAnisotropy(context),
    extensions: context.getSupportedExtensions(),
    ...getWebGL2Status(context),
  }
}

import { toastController } from '@ionic/vue'

export const showToast = async (message, duration = 5000) => {
  const toast = await toastController.create({
    header: message, // this.$t('diagnostic.generated'),
    position: 'top',
    duration,
    color: 'dark',
    cssClass: 'rn-toast',
  })
  await toast.present()
}

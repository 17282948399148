import { pick, omitBy, mapKeys, camelCase } from 'lodash'

export const removePrefix = (obj, prefix, properties) => {
  const length = typeof prefix === 'string' ? prefix.length : 0
  const picked = pick(obj, properties)
  return length <= 0
    ? picked
    : mapKeys(picked, (value, key) =>
        key.startsWith(prefix) ? key.substring(length) : key
      )
}

export const toCamelCase = (obj, properties) =>
  mapKeys(pick(obj, properties), (value, key) => camelCase(key))

export const removeEmptyProperties = obj => omitBy(obj, v => !v)

export const removePrefixAndToCamelCase = (obj, prefix, properties) => {
  const length = typeof prefix === 'string' ? prefix.length : 0
  return mapKeys(pick(obj, properties), (value, key) =>
    camelCase(
      length > 0 && key.startsWith(prefix) ? key.substring(length) : key
    )
  )
}

import { StatusBar, Style } from '@capacitor/status-bar'

import { isRunningWithCapacitor } from './device'

// status bar https://capacitorjs.com/docs/apis/status-bar#setbackgroundcolor

export const setStatusBar = async color => {
  if (isRunningWithCapacitor()) {
    await StatusBar.setStyle({ style: Style.Light })
    await StatusBar.setBackgroundColor({ color })
  }
}

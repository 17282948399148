import { Device } from '@capacitor/device'
import { isPlatform } from '@ionic/vue'

export const getDeviceLanguage = async () =>
  (await Device.getLanguageCode())?.value.substring(0, 2)

export const isRunningWithCapacitor = () => isPlatform('capacitor')

export const shouldBeAbleToDownload = () => true

export const isTouchScreen = () =>
  window && typeof window.matchMedia === 'function'
    ? window?.matchMedia('(pointer: coarse)').matches
    : false // some environments (unit tests) don't have variable window

async function getBackgroundFetchWithId(id) {
  const serviceWorker = await navigator.serviceWorker.ready
  return serviceWorker.backgroundFetch
    ? await serviceWorker.backgroundFetch.get(id)
    : null
}

async function checkBackgroundFetchWithId(id) {
  const reg = await getBackgroundFetchWithId(id)
  if (reg) {
    console.log(
      `background fetch with id ${id} already in progress | result = ${reg.result} | downloaded = ${reg.downloaded} bytes`
    )
    return false
  } else {
    return true
  }
}

// async function onBackgroundFetchProgress(commit, id) {
//   const registration = await getBackgroundFetchWithId(id)
//   if (registration) {
//     commit('updateBackgroundFetch', { registration })
//   }
//   return registration
// }

export async function beginBackgroundFetchForRequests(
  { commit },
  { id, name = '', projectId = '', requests = [] }
) {
  if (
    !Array.isArray(requests) ||
    requests.length === 0 ||
    !checkBackgroundFetchWithId(id)
  ) {
    return
  }
  const title = `Downloading ${name}`
  const serviceWorker = await navigator.serviceWorker.ready
  const reg = await serviceWorker.backgroundFetch.fetch(id, requests, { title })
  commit('addBackgroundFetch', { registration: reg, name, projectId })
  // reg.onprogress = () => onBackgroundFetchProgress(commit, id)
  // setTimeout(async function trackBackgroundFetchProgress() {
  //   const reg = await onBackgroundFetchProgress(commit, id)
  //   if (reg?.result === '') setTimeout(trackBackgroundFetchProgress, 1000)
  // }, 500)
}

export async function beginBackgroundFetchForUrlList(
  { dispatch },
  { id, name = '', projectId = '', url: baseUrl = null }
) {
  if (!baseUrl || !checkBackgroundFetchWithId(id)) return
  let requests = []
  const response = await fetch(baseUrl)
  if (response.ok) {
    const content = await response.text()
    requests = content
      .split(/\r?\n/)
      .filter(l => l.length > 0)
      .map(l => new URL(l, baseUrl))
    console.log(
      `will fetch ${requests.length} URLs as ${name} (project ${projectId})`
    )
  } else {
    console.error(
      `could not retrieve list of URLs: ${response.status} - ${response.statusText}`
    )
  }
  dispatch('beginBackgroundFetchForRequests', { id, name, projectId, requests })
}

export async function abortAllBackgroundFetchesMatching(
  ctx,
  { regex = '' } = {}
) {
  const serviceWorker = await navigator.serviceWorker.ready
  const bg = serviceWorker.backgroundFetch
  const ids = (await bg?.getIds()) || []
  for (const id of ids) {
    const reg = !regex || id.match(regex) ? await bg.get(id) : null
    if (reg) {
      console.log(`aborting background fetch ${id}`)
      reg.abort()
    }
  }
}

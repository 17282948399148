export const OfflineItemStatus = Object.freeze({
  no: 'no',
  yes: 'yes',
  partial: 'partial',
  pending: 'pending',
  downloading: 'downloading',
  failed: 'failed',
})

export const ConnectionKind = Object.freeze({
  none: 'none',
  unknown: 'unknown',
  wifi: 'wifi',
  cellular: 'cellular',
})

export const ConnectionSpeed = Object.freeze({
  none: 'none',
  unknown: 'unknown',
  slow: 'slow',
  medium: 'medium',
  fast: 'fast',
})

export const isPartiallyOfflineStatus = status =>
  (status != null && status !== OfflineItemStatus.no) ||
  status !== OfflineItemStatus.failed

export const isInProgressStatus = status =>
  status === OfflineItemStatus.pending ||
  status !== OfflineItemStatus.downloading

export const offlineInfo = () => ({
  current: 0,
  total: 0,
  status: OfflineItemStatus.no,
})

// Layers can be shared between several projects,
// so we need to track the n-to-n relationship,
// in order to correctly update the project status
// and cancel the downloading of data at the project level.
// For example, if a layer is downloaded for several offline projects,
// we must keep layer data until the last referencing project
// is removed from 'offline' mode.
export const defaultInfoForLayer = layerId => ({
  layerId: layerId,
  ...offlineInfo(),
  usedByProjectIds: [],
})

// We also need to retrieve data such as project properties and members
// These entities are not really 'downloaded', but rather fetched and stored
// in Vuex, as when the user uses the application
export const defaultInfoForProject = projectId => ({
  projectId: projectId,
  ...offlineInfo(),
  // Layers have their own 'top-level' fetch info (see `defaultInfoForLayer`),
  // which is in the state `byLayerId` property.
  layers: {},
  // Contrary to layers, annotations do not have 'top-level' fetch info,
  // because an annotation belongs to a project and thus cannot be shared
  // between several projects.
  // We download all annotations as a single unit because an annotation
  // contains 0 or 1 file
  annotations: offlineInfo(),
})

import {
  defaultInfoForLayer,
  defaultInfoForProject,
  isPartiallyOfflineStatus,
} from '@/store/offline/offline.utils'
import { forOwn, values } from 'lodash'
import { safeArray } from '@/utils/array.js'

export const networkInfo = state => state.networkInfo

export const getLayerOfflineInfo = state => layerId =>
  state.byLayerId[layerId] || defaultInfoForLayer(layerId)

export const getProjectOfflineInfo = state => projectId =>
  state.byProjectId[projectId] || defaultInfoForProject(projectId)

export const getProjectOfflineLayerInfos = (state, getters) => projectId => {
  const projectInfo = state.byProjectId[projectId]
  if (!projectInfo) return {}
  const result = {}
  forOwn(projectInfo, (value, key) => {
    const f = value.foreground
      ? getters.getForegroundFetchById(value.fetchId)
      : getters.getBackgroundFetchById(value.fetchId)
    if (f) result[key] = f
  })
  return result
}

export const getProjectOfflineStatus = state => projectId =>
  state.offlineStatusByProjectId[projectId]

export const countAtLeastPartiallyOfflineLayers = state =>
  safeArray(
    values(state.byLayerId).filter(info =>
      isPartiallyOfflineStatus(info?.status)
    )
  ).length

export const storageInfo = state => state.storage || {}

export const storageUsageInMegaBytes = state => {
  let usage = state.storage?.usage
  if (usage == null) return null
  if (usage === 0) return 0
  usage /= 1024 * 1024
  if (usage >= 100) return Math.round(usage)
  else if (usage >= 10) return Math.round(10 * usage) / 10
  else return Math.round(100 * usage) / 100
}

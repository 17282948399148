/**
 * save the data into local storage or mobile storage
 *
 * /!\ We don't use normal plugin https://github.com/bichikim/vuex-storage#readme but ionic storage version
 *
 * - @see https://stackoverflow.com/questions/69043413/ionic-storage-with-vue
 * - @see https://stackoverflow.com/questions/65431935/capacitor-storage-or-cordova-storage
 * - @see https://vuex.vuejs.org/guide/plugins.html
 * - @see https://github.com/ionic-team/ionic-storage
 */

import { Storage, Drivers } from '@ionic/storage'
import { pick } from 'lodash'

const key = 'com.rimnat.app'

const initStorage = async () => {
  const storage = new Storage({
    driverOrder: [
      Drivers.LocalStorage,
      Drivers.SecureStorage,
      Drivers.IndexedDB,
    ],
  })
  const instance = await storage.create()
  const data = (await instance.get(key)) || {}
  return { storage: instance, data }
}

export const StoragePluginFactory = async ({ vuex } = {}) => {
  if (vuex == null) throw new Error('Vuex store is missing')
  const { storage, data } = await initStorage()
  return {
    install: app => {
      app.config.globalProperties.$ionicStorage = storage
      vuex.$ionicStorage = storage
      vuex.replaceState({
        ...vuex.state,
        ...data,
      })
    },
  }
}

const persistedModules = [
  'annotations',
  'fetchForeground',
  'layers',
  'media',
  'offline',
  'portal',
  'project',
  'user',
  'workState',
]

// these modules data is not saved into API so
// it is better to keep it in local storage
const modulesToSave = ['auth', 'settings', 'help', ...persistedModules]

export const vuexStorage = store => {
  // called when the store is initialized
  store.subscribe((mutation, state) => {
    if (store.$ionicStorage)
      store.$ionicStorage.set(key, {
        ...pick(state, modulesToSave),
        diagnostic: pick(state.diagnostic, ['persistent']),
      })
  })
}

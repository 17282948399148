/**
 * @deprecated use projectById or getProjectsByPortalId instead
 */
export const projects = state => {
  console.warn(
    'projects is deprecated, use projectById or getProjectsByPortalId instead'
  )
  return Object.values(state.byId).map(p => p.project)
}

export const projectById = state => projectId => state.byId[projectId]?.project

export const getProjectsByPortalId = state => portalId =>
  (state.byPortalId[portalId]?.listOfIds || []).map(projectById(state))

export const projectMembersbyProjectId =
  (state, getters, rootState, rootGetters) => projectId =>
    (state.byId[projectId]?.memberIds || []).map(userId =>
      rootGetters.userById(userId)
    )

export const projectPermissionsByProjectId = state => projectId =>
  state.byId[projectId]?.memberPermissions || []

export const areProjectsFetched = state => portalId =>
  state.byPortalId[portalId]?.areAllFetched

export const isProjectFetched = state => projectId =>
  state.byId[projectId]?.isFetched

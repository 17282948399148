import * as mutations from './annotations.mutations'
import * as getters from './annotations.getters'
import * as actions from './annotations.actions'

import { addReset } from '../utils'

const state = {
  colors: ['#63D82F', '#3C2FD8', '#D92626', '#D9B726', '#FDFD46', '#F646FD'],
  byId: {},
  listOfIdsByProjectId: {},
  displayedAnnotationByProjectId: {},
  pendingAnnotationIds: [],
  areDeleting: [],
}

export default addReset({
  state,
  actions,
  mutations,
  getters,
})

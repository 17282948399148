import { convertToJs } from '@/utils/modelConvertors/user.converter'
import { convertToJs as toPermissions } from '@/utils/modelConvertors/permissions.global.converter'

export async function fetchUser({ commit }, { email }) {
  const params = { user_email: email }

  const { data } = await this.$api.get('/user/getUserByEmail', { params })

  const user = convertToJs(data)

  commit('addUser', user)
  return user
}

async function fetchUserGlobalCaps(api, userId) {
  const params = { user_id: userId }
  const { data } = await api.get('/user/getUserGlobalCaps', { params })
  return toPermissions(data)
}

export async function fetchAuthenticatedUser({ dispatch, commit, getters }) {
  if (!getters.isAuthenticated) {
    return
  }

  const email = getters.idTokenPayload?.email

  const user = await dispatch('fetchUser', { email })
  user.permissions = { global: await fetchUserGlobalCaps(this.$api, user.id) }

  commit('setCurrentUser', user)
}

import { v4 as uuidv4 } from 'uuid'
import config from '@/utils/config'

async function getMediaUrl(api, mediaId) {
  try {
    const { data } = await api.get('/media/getMediaUrl', {
      params: {
        media_id: mediaId,
      },
    })
    return data
  } catch (error) {
    console.error(`failed to getMedialUrl(${mediaId}]`, error)
  }
}

async function postImageMedia(api, image) {
  const form = new FormData()
  form.append('file', image)
  const options = {
    baseURL: config.imageServerUrl,
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { type: 'media' },
    // timeout: 10000,
  }
  try {
    const { data: id } = await api.post('/upload.php', form, options)
    const url = await getMediaUrl(api, id)
    return { id, url }
  } catch {
    // if we did not get a response from the server, assume it is a connectivity issue
    // we will convert the image to an object URL (in mutation)
    const id = 'local_' + uuidv4()
    return { id, image }
  }
}

export async function fetchMedia({ commit }, mediaId) {
  const url = await getMediaUrl(this.$api, mediaId)
  if (!url) return
  const media = { id: mediaId, url }
  commit('setMedia', { media })
  return media
}

export async function uploadImage({ commit }, image) {
  // image is a Blob
  const media = await postImageMedia(this.$api, image)
  if (media) {
    // if (has(media, 'image')) media.image = new Blob([image])
    commit('setMedia', { media })
  }
  return media
}

export async function savePendingMedia({ state, commit }, { mediaId }) {
  const media = state.byId[mediaId]
  if (!media) return
  const posted = await postImageMedia(this.$api, media.image)
  if (posted?.id && posted?.url) {
    commit('setMediaServerId', {
      localId: mediaId,
      serverId: posted.id,
      url: posted.url,
    })
  }
  return posted
}

export const setLayers = (
  state,
  { layers, portalId = null, projectId = null }
) => {
  layers.forEach(layer => {
    state.byId[layer.id] = layer
  })
  if (portalId) {
    const keyToId = {}
    layers.forEach(l => (keyToId[l.key] = l.id))
  }
  if (projectId) {
    state.listOfIdsByProjectId[projectId] = layers.map(l => l.id)
  }
}

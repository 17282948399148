import { ProjectAccess } from '@/utils/project'
import { convertToJs as toPermissions } from '@/utils/modelConvertors/permissions.project.converter'

export const convertToJs = httpProject => {
  let keywords = []
  try {
    const input = httpProject.keywords
    if (typeof input === 'string' && input.length > 0)
      keywords = JSON.parse(input) || []
  } catch (error) {
    console.error('impossible to parse keywords', error, httpProject.keywords)
  }

  return {
    id: httpProject.project_id,
    portalId: httpProject.portal_id,
    name: decodeURIComponent(httpProject.project_nicename),
    slug: httpProject.project_slug,
    keywords,
    status: httpProject.project_status,
    coordinateSystem: {
      id: httpProject.project_coordinate_system,
      code: '',
      name: '',
      proj4: '',
    },
    currentWorkState: {
      id: -1,
      name: '',
      content: '',
    },
    longitude: httpProject.project_lng,
    latitude: httpProject.project_lat,
    permissions: toPermissions(httpProject),
    legendMediaId: httpProject.project_legend_media_id,
    access: convertRequestAccessToJs(httpProject),
    raw: httpProject,
  }
}

// TODO merge with permissions.access
export const convertRequestAccessToJs = httpProject => {
  let status = ProjectAccess.ACCESSIBLE
  if (httpProject.project_status === 'public') {
    status = ProjectAccess.ACCESSIBLE
  } else if (httpProject?.user_accessible === '1') {
    status = ProjectAccess.ACCESSIBLE
  } else if (httpProject?.accepted === '0' && httpProject?.closed === '1') {
    status = ProjectAccess.REJECTED
  } else if (httpProject?.accepted === '1' && httpProject?.closed === '1') {
    status = ProjectAccess.ACCEPTED
  } else if (httpProject?.sent === '1') {
    status = ProjectAccess.IN_PROGRESS
  } else {
    status = ProjectAccess.NOT_ACCESSIBLE
  }

  return {
    status,
    hash:
      httpProject.request_hash != undefined ? httpProject.request_hash : null,
  }
}

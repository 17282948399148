const initializeNote = (state, projectId) => {
  state.byProjectId[projectId] = {
    files: [],
    content: '',
  }
}

export const setPersonalNoteContent = (state, { projectId, content }) => {
  if (!state.byProjectId[projectId]) {
    initializeNote(state, projectId)
  }

  state.byProjectId[projectId].content = content
}

import auth0 from 'auth0-js'
import { computed, reactive } from 'vue'
import { buildDeepLink } from '@/utils/links'
import { isRunningWithCapacitor } from '@/utils/device'

/**
 * =========================== /!\ ======================
 * This implementation is caused by legacy code on HTTP API server using
 * token Id instead of access token. Using token Id causes multiple security issues
 * discussed with the Geolithe team. It has been decided to keep using this implementation
 *
 * The standard auth0 guidelines suggest to use access token as implemented in file _auth.js_
 *
 * - https://auth0.com/docs/security/data-security/token-storage
 * - https://auth0.com/blog/id-token-access-token-what-is-the-difference/
 */

let webAuth

const logout = options => {
  webAuth.logout(options)
}

const getIdTokenClaims = async options => {
  return new Promise((resolve, reject) => {
    auth0.checkSession(options, function (err, authResult) {
      if (err) {
        reject(err)
      } else {
        resolve(authResult)
      }
    })
  })
}

let saveToken = () => {}
let redirectUri = ''

const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null,
  authResult: null,
})

const renewTokens = options =>
  new Promise((resolve, reject) => {
    webAuth.checkSession(options, (error, authResult) => {
      if (error) {
        reject(error)
      } else {
        state.authResult = authResult
        resolve(authResult)
      }
    })
  })

const login = customState => {
  if (isRunningWithCapacitor()) {
    console.log('isRunning capacitor')
    // cf https://auth0.com/docs/libraries/auth0js#webauth-popup-authorize-
    webAuth.popup.authorize(
      {
        redirectUri,
        appState: customState,
      },
      (error, authResult) => {
        if (error) {
          console.error('error', error)
        } else {
          console.log('connected', authResult)
          state.authResult = authResult
          state.user = authResult.idTokenPayload
          saveToken(authResult)
        }
      }
    )
  } else {
    console.log('is not running capacitor')
    webAuth.authorize({
      appState: customState,
    })
  }
}

let afterLogin = () => {}

const handleRedirectCallback = url => {
  const fullURL = new URL(url)
  // https://auth0.com/docs/libraries/auth0js#webauth-popup-authorize-
  webAuth.parseHash({ hash: fullURL.hash }, (err, authResult) => {
    if (err) {
      return console.log(err)
    }

    state.authResult = authResult
    state.user = authResult.idTokenPayload
    saveToken(authResult)
    afterLogin()

    // webAuth.client.userInfo(authResult.accessToken, function (error, user) {
    //   // Now you have the user's information
    // })
  })
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getIdTokenClaims,
  authResult: computed(() => state.authResult),
  // getTokenSilently,
  // getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect: login,
  loginWithPopup: login,
  logout: logout,
  clear: () => {},
  updateApi: () => {},
  renewTokens,
}

export const auth = () => authPlugin

const useAuth0 = async ({
  afterLoginRoute,
  save,
  onRedirectCallback,
  ...options
}) => {
  redirectUri = await buildDeepLink(afterLoginRoute?.path)
  afterLogin = options.afterLogin

  console.log(redirectUri)
  webAuth = new auth0.WebAuth({
    domain: options.domain,
    responseType: 'id_token',
    scope: 'openid profile email',
    redirectUri: redirectUri,
    clientID: options.clientId,
  })

  saveToken = save

  webAuth.parseHash((err, authResult) => {
    if (err) {
      console.error(err)
    } else {
      if (authResult) {
        state.authResult = authResult
        state.user = authResult.idTokenPayload
        saveToken(authResult)
        onRedirectCallback()
      }
    }
  })

  return authPlugin
}

export const Auth0Plugin = {
  install(app, options) {
    const save = authResult => {
      app.config.globalProperties.$store.commit('setToken', authResult)
      // app.config.globalProperties.$store.dispatch('refreshApi')
    }

    const afterLogin = () => {
      app.config.globalProperties.$router.push(options.afterLoginRoute)
    }

    state.authResult = app.config.globalProperties.$store.getters.authResult

    useAuth0({
      i18n: app.config.globalProperties.$i18n,
      save,
      afterLogin,
      ...options,
    })

    app.config.globalProperties.$auth = authPlugin
    app.config.globalProperties.$store.$auth = authPlugin
  },
}

import { isValidURL } from '@/utils/links'

export const AnnotationTypes = {
  TEXT: 'text',
  URL: 'url',
  PHOTO: 'photo',
  REALTIME: 'realtime',
}

export const annotationOptions = [
  {
    label: 'annotations.types.text',
    value: AnnotationTypes.TEXT,
  },
  {
    label: 'annotations.types.url',
    value: AnnotationTypes.URL,
  },
  {
    label: 'annotations.types.photo',
    value: AnnotationTypes.PHOTO,
  },
  {
    label: 'annotations.types.realtime',
    value: AnnotationTypes.REALTIME,
  },
]

export const isUrlAnnotation = annotation =>
  annotation.type === AnnotationTypes.URL && isValidURL(annotation.url)

export const isRealTimeAnnotation = annotation =>
  annotation.type === AnnotationTypes.REALTIME && isValidURL(annotation.url)

export const isTextAnnotation = annotation =>
  annotation.type === AnnotationTypes.TEXT

export const isPhotoAnnotation = annotation =>
  annotation.type === AnnotationTypes.PHOTO && annotation.mediaId

export const isValidAnnotation = annotation =>
  annotation.title &&
  annotation.target &&
  (isUrlAnnotation(annotation) ||
    isRealTimeAnnotation(annotation) ||
    isTextAnnotation(annotation) ||
    isPhotoAnnotation(annotation))

export const createDefaultAnnotation = more => ({
  id: null,
  title: '',
  type: AnnotationTypes.TEXT,
  url: '',
  mediaId: 0,
  description: '',
  color: '',
  projectId: null,
  target: [],
  camera: [],
  placeholder: [],
  group: '',
  ...more,
})

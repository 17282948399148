import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
import { TroisJSVuePlugin } from 'troisjs'

import App from './App.vue'

import { Auth0Plugin } from '@/plugins/auth.unsafe'
import { i18n } from '@/plugins/i18n'
import { router, afterLoginRoute } from '@/plugins/router'
import { ApiPlugin } from '@/plugins/api'
import { StoragePluginFactory } from '@/plugins/storage'

import config from '@/utils/config'

import { store } from '@/store'

// https://dev.to/devmount/how-to-use-ionicons-v5-with-vue-js-53g2

import {
  domain,
  clientId,
  serverUrl,
  appId,
  imageServerUrl,
} from '../auth.config.json'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

import './theme/index.scss'

config.domain = domain
config.serverUrl = serverUrl
config.imageServerUrl = imageServerUrl

const createAppWithStorage = storage => {
  const app = createApp(App)
  if (storage) app.use(storage)
  // It will no longer be required after vue 3.3.
  // @see https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity
  app.config.unwrapInjectedRef = true
  return app
    .use(IonicVue)
    .use(router)
    .use(store)
    .use(storage)
    .use(i18n)
    .use(ApiPlugin, { serverUrl, appId })
    .use(Auth0Plugin, {
      domain,
      clientId,
      afterLoginRoute,
      onRedirectCallback: appState => {
        router.push(appState?.targetUrl || window.location.pathname)
      },
    })
    .use(TroisJSVuePlugin)
}

StoragePluginFactory({ vuex: store })
  .then(storage => createAppWithStorage(storage))
  .then(app => {
    router.isReady().then(() => {
      app.mount('#app')
    })
  })
  .catch(error => console.error(error))

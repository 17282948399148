export default function pendingValue({ timeout = 0 } = {}) {
  let promiseResolve = null
  let promiseReject = null
  let timeoutID = 0
  const promise = new Promise((resolve, reject) => {
    promiseResolve = resolve
    promiseReject = reject
  })
  const cleanup = obj => {
    if (timeoutID > 0) clearTimeout(timeoutID)
    timeoutID = 0
    promiseResolve = null
    promiseReject = null
    return obj
  }
  const resolve = value => {
    const done = cleanup(promiseResolve)
    if (done) done(value)
  }
  const reject = error => {
    const failed = cleanup(promiseReject)
    if (failed) failed(error)
  }
  if (timeout > 0) setTimeout(() => reject(), timeout)
  return { promise, resolve, reject }
}

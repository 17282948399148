import { createRouter, createWebHistory } from '@ionic/vue-router'

import { setStatusBar } from '@/utils/statusBar'

import { store } from '@/store/index'

const routes = [
  {
    path: '/',
    redirect: { name: 'projectList' },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/components/views/Login.vue'),
    meta: { title: 'title.Login' },
  },
  {
    name: 'logged',
    path: '/logged',
    component: () => import('@/components/views/Logged.vue'),
    beforeEnter: () => {
      // this route exists only to wait until the Vuex store contains the token
      // if the token is already there, redirect to default page
      if (store.getters?.idTokenPayload) return '/'
    },
  },
  {
    name: 'projectList',
    path: '/projects/:menu?',
    component: () => import('@/components/views/ProjectList.vue'),
    meta: { title: 'title.Projects', isProtected: true },
  },
  {
    name: 'projectListRetrocompatibility',
    path: '/:portalSlug/projects/:menu?',
    component: () => import('@/components/views/ProjectList.vue'),
    meta: { title: 'title.Projects', isProtected: true },
  },
  {
    name: 'personal-note',
    path: '/project/:projectId/personal-note',
    component: () => import('@/components/views/PersonalNotePage.vue'),
    meta: { title: 'title.PersonalNote', isProtected: true },
  },
  {
    name: 'project',
    path: '/project/:projectId/:menu?',
    component: () => import('@/components/views/Map3D.vue'),
    meta: { isProtected: true, statusBarColor: '#e5e5e5' },
  },
  {
    name: 'projectDetails',
    path: '/project/:projectId/details/:menu?',
    component: () => import('@/components/views/Project.vue'),
    meta: { isProtected: true },
  },
  {
    name: 'preferences',
    path: '/preferences',
    component: () => import('@/components/views/Preferences.vue'),
    meta: { title: 'title.Preferences', isProtected: true },
  },
  // { path: '/:pathMatch(.*)*', redirect: '/' },
]

export const findRoute = (name, params = null) => ({
  ...routes.find(r => r.name === name),
  ...(params ? { params } : null),
})

export const afterLoginRoute = findRoute('logged')
export const afterLogoutRoute = findRoute('login')

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const history = []

export const back = (name, params = null) => {
  console.log('back', history)
  if (history.length > 1) {
    // 3 is apparently the good number in browsers
    router.back()
  } else {
    console.log('cannot go back')
    router.push(findRoute(name, params))
  }
}

router.beforeEach(to => {
  if (to.meta.isProtected) {
    const token = store.getters?.idTokenPayload
    if (token == null) {
      return { name: 'login' }
    }
  }
})

router.afterEach(async to => {
  /**
   * status bar color shouldn't be managed there. page Component would be more adapted
   * unfortunately, vue.js 3.x doesn't provide callback to detect when component
   * is mounted again because of reusable components
   * @see https://stackoverflow.com/questions/57699473/vuejs-hook-mounted-is-not-called-when-page-is-re-open-by-vue-router
   * @see https://stackoverflow.com/questions/61718251/vuejs-not-triggering-mounted-after-user-pressed-back-on-his-browser
   * and all callbacks tested were not fitting our needs (watch.$route, activated, etc)
   */
  await setStatusBar(to?.meta.statusBarColor || '#ffffff')

  const IsItABackButton = window.popStateDetected
  window.popStateDetected = false

  if (IsItABackButton) {
    history.pop()
  } else {
    history.push(to)
  }
})

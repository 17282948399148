import { removePrefix, toCamelCase } from '@/utils/object'

export const convertToJs = httpLayer => ({
  ...removePrefix(httpLayer, 'layer_', [
    'layer_name',
    'layer_key',
    'layer_server',
    'layer_type',
    'layer_size',
    'layer_origin',
    'layer_url',
  ]),
  ...toCamelCase(httpLayer, [
    'layer_id',
    'id',
    'project_id',
    'default_visibility',
    'layer_4d_min',
    'layer_4d_max',
    'dfx_layers_styles',
  ]),
  defaultPointcloudMaterial: {
    size: httpLayer.default_pointcloud_material_size,
    style: httpLayer.default_pointcloud_material_style,
    color: httpLayer.default_pointcloud_material_color,
    pointSizeType: httpLayer.default_pointcloud_material_pointsizetype,
  },
  shp: {
    point: {
      size: httpLayer.shp_point_size,
      style: httpLayer.shp_point_style,
      color: httpLayer.shp_point_color,
    },
    polygon: {
      filled: httpLayer.shp_polygon_filled,
      fillColor: httpLayer.shp_polygon_fill_color,
      outLined: httpLayer.shp_polygon_outlined,
      outlineColor: httpLayer.shp_polygon_outline_color,
      outlineWidth: httpLayer.shp_polygon_outline_width,
      clampToGround: httpLayer.shp_polygon_clamp_to_ground,
    },
    containsHeight: Boolean(httpLayer.shp_contains_height),
    tag: httpLayer.shp_tag,
  },
  raw: httpLayer,
})

<template>
  <div class="h-0 w-0 overflow-hidden">
    <renderer ref="renderer" width="2" height="2" id="diagnostic-renderer">
      <camera :position="{ x: 2, y: -2, z: 10 }"></camera>

      <scene>
        <mesh name="Cube"></mesh>
      </scene>
    </renderer>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Camera, Mesh, Renderer, Scene } from 'troisjs'
import { mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import { getContext } from '@/utils/diagnostic/context'

export default defineComponent({
  components: {
    Camera,
    Renderer,
    Scene,
    Mesh,
  },
  watch: {
    async isDiagnosticRunning(isRunning) {
      if (this.$refs.renderer && isRunning) {
        const silent = this.$store.getters.shouldRunDiagnosticSilently
        try {
          const context = await getContext(this.$refs.renderer)
          await this.$store.dispatch('runDiagnostic', context)

          if (!silent) {
            await showToast(this.$t('diagnostic.generated'))
          }
        } catch (error) {
          await showToast(this.$t('diagnostic.error'))
        } finally {
          await this.$store.commit('stopDiagnostic')
        }
      }
    },
  },
  computed: {
    ...mapGetters(['isDiagnosticRunning']),
  },
})
</script>
